<template>
  <div class="showRight" :class="!parent? 'showRightFlex':''">
    <div class="leftDiv" v-if="isCollect < 1">
      <template v-if="!select">
        <template v-if="showRight">
          <img  src="@/assets/img/helpStudy/right.png" alt=""><span class="right">答案正确</span>
        </template>
        <template v-else>
          <img src="@/assets/img/helpStudy/error.png" alt=""><span class="error">答案错误</span>
        </template>
      </template>
      <template v-else>
        <van-radio-group v-model="checked" direction="horizontal" class="selectGroup" @change="changeSelect" :disabled="remainTime=='预览' || remainTime == '我的错题'">
          <van-radio class="right" name="1">
            <template #icon="props">
              <van-icon name="" />
            </template>
            <span :class="checked=='1' ? 'checked':'none'">答案正确</span>
          </van-radio>
          <van-radio class="error" name="0">
            <template #icon="props">
              <van-icon name="" />
            </template>
            <span :class="checked=='0' ? 'checked':'none'">答案错误</span>
          </van-radio>
        </van-radio-group>
      </template>
    </div>
    <div class="leftDiv" v-else></div>
    <div class="rightDiv">
      <!-- 分数 -->
      <div @click="showAnswerSheet" v-if="!parent">
        <span>{{ num }}/{{ examTotal }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'rightErrorTitle',
  props: {
    parent: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    select: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    showRight: {
      type: Boolean,
      default: function () {
        return null
      }
    },
    selfScore: {
      type: String,
      default: function () {
        return null
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    questionId: {
      type: Number,
      default: function () {
        return null
      }
    },
    trueFlag: {
      type: Number,
      default: function () {
        return null
      }
    },
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    remainTime: {
      // 剩余时间 (s) remainTime='批阅/自改'(批阅状态或者练习自改状态)
      type: String,
      default: function () {
        return ''
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  data () {
    return {
      checked: '',
      selfScoreNew: null
    }
  },
  created () {
    if (this.question) {
      this.selfScoreNew = this.question.selfScore
      if (this.question.trueFlag==1) {
        this.checked = '1'
      } else if (this.question.trueFlag==0) {
        this.checked = '0'
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      console.log(this.question.trueFlag, 'trueFlag');
    })
  },
  methods: {
    // 调取答题卡
    showAnswerSheet () {
      this.$emit('showAnswerSheet')
    },
    changeSelect () {
      console.log(this.checked,this.selfScoreNew,this.question,this.num, this.isExercises)
      if ((this.selfScoreNew || this.selfScoreNew == 0 || this.isExercises) && this.question.typeId < 7) {
        // let num = this.num
        // if (this.parent) {
        //   num = this.num
        // }
        let obj = {
          trueFlag: this.checked,
          questionId: this.question.questionId,
          typeId: this.question.typeId,
          num: this.num
        }
        if(this.isExercises) {
          // 专项下批阅需添加新字段
          obj.courseId = this.question.courseId
          obj.selfAnswer = this.question.selfAnswer
        }
        this.$emit('changeSelect', obj)
        console.log(obj,'titleObj');
      } else if((this.selfScoreNew || this.selfScoreNew == 0 || this.isExercises) && this.question.typeId >= 7) {
        let obj = {
          trueFlag: this.checked,
          questionId: this.question.questionId,
          num: this.num
        }
        if (this.question.childrenList.length > 0) {
          let flag = 0
          for (let i in this.question.childrenList) {
            if (this.question.childrenList[i].trueFlag == -1) {
              flag++
              break
            }
            if (i == this.question.childrenList.length - 1 && flag==0) {
              this.$emit('changeSelect', obj)
            } else {
              this.checked = ''
              Toast('请您先打分再判对错')
            }
          }
        } else {
          this.$emit('changeSelect', obj)
        }
      } else {
        Toast('请您先打分再判对错')
        this.checked = ''
      }
    },
    updateScore (val) {
      console.log('****',val);
      this.selfScoreNew = Number(val)
    }
  }
}
</script>

<style lang="scss" scoped>

.showRightFlex {
  display: flex;
  align-items: center;
  // position: absolute;
  // top: -16px;
}
.showRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  .leftDiv {
    height: 16px;
    width: auto;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    span {
      width: 100px;
    }
    img {
      height: 12px;
      padding-right: 5px;
    }
    .right {
      color: #1CB74D;
    }
    .error {
      color: #E21A1A;
    }
    .selectGroup {
      .right {
        ::v-deep.none {
          color: #666666;
        }
        ::v-deep.checked {
          color: #1CB74D;
        }
        ::v-deep .van-radio__icon--checked.van-icon  {
          color: #fff !important;
          background-color: #1CB74D !important;
          border-color: #1CB74D !important;
        }
      }
      .error {
        ::v-deep.none {
          color: #666666;
        }
        ::v-deep.checked {
          color: #E21A1A;
        }
        ::v-deep .van-radio__icon--checked {
          ::v-deep .van-icon {
            color: #fff !important;
            background-color: #E21A1A !important;
            border-color: #E21A1A !important;
          }
        }
      }
    }
  }
  .rightDiv {
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 12px;
  }
}
::v-deep .selectGroup .right .van-radio__icon--checked .van-icon{
  color: #fff !important;
  background-color: #1CB74D !important;
  border-color: #1CB74D !important;
}
::v-deep .selectGroup .error .van-radio__icon--checked .van-icon{
  color: #fff !important;
  background-color: #E21A1A !important;
  border-color: #E21A1A !important;
}
</style>
