<!--
 * @Description:  问答题
 -->

<template>
  <div class="question question-question-answer-exam" :style="{height:(parent? 'auto':'calc(100vh - 58px - 70px)')}">
    <rightErrorTitle
      v-if="(!select && !parent) || specialEvent()"
      ref="rightErrorTitle"
      :select='true'
      :num='num'
      :remainTime='remainTime'
      :question='question'
      :examTotal='examTotal'
      @showAnswerSheet='showAnswerSheet'
      @changeSelect='changeSelect'
      :isExercises='isExercises'
      :isCollect="isCollect"
    ></rightErrorTitle>
    <div class="title">
      <!-- <span class="kinds">问答题</span> -->
      <span v-if="parent">({{ num }}).(问答题) </span>
      <span v-else>{{ num }}.(问答题) </span>
      <span
        class="stem"
        ref="stem"
        v-html="question.stem"
      ></span>
    </div>
    
    <div class="content">
      <van-field
        @change="inputSubmit"
        v-model="selfAnswer"
        autosize
        type="textarea"
        placeholder="请输入答案"
        :label-width="`${question.fontSizeMultiple * 40}px`"
        :class="{bg_gary:!select}"
        :disabled="!select || (isExercises && isSubmit)"
      />
      <van-button
        v-if="!isSubmit && isExercises"
        class="submit"
        round size="small"
        color="#18A6F3"
        type="info"
        @click="submit">提 交</van-button>
      <van-button
        v-if="isSubmit && isExercises"
        class="submit"
        round size="small"
        color="#aaa"
        type="info">已提交</van-button>
    </div>
    <answerArea
      ref="answerArea"
      v-if="!select || (isExercises && isSubmit)"
      :question='question'
      :remainTime='remainTime'
      @updateScore='updateScore'
      :isExercises='isExercises'
      :isCollect="isCollect"
    ></answerArea>
    <rightErrorTitle
      v-if="(!select && parent) || (isExercises && parent && isSubmit)"
      ref="rightErrorTitle2"
      :select='true'
      :parent='parent'
      :num='num'
      :isExercises='isExercises'
      :remainTime='remainTime'
      :question='question'
      @changeSelect='changeSelect'
      :isCollect="isCollect"
    ></rightErrorTitle>
      <!-- <div
        v-if="isExercises && !select && !hasAnswered(question.selfAnswer, question.answer)"
        class="analysis"
      >
        答案：<span v-html="question.answer || '暂无'">{{}}</span>
      <div class="anly">
        解析：<span>{{ question.analysis || "暂无" }}</span>
      </div> -->
    <!-- </div> -->
  </div>
</template>

<script>
import { Toast } from "vant"
import rightErrorTitle from '../rightErrorTitle'
import answerArea from '../answerArea'
/*
 * 去除字符串中的所有空格
 */
function removeAllSpace(str) {
  if (!str || typeof str !== "string") return str;
  return str.replace(/\s+/g, "").replace("&nbsp;", "").replace(/ /g, "");
}
export default {
  components: {
    rightErrorTitle,
    answerArea,
  },
  name: "QuestionAnswer",
  props: {
    question: {
      type: Object,
      default: function () {
        return {};
      },
    },
    num: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    select: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    parent: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    remainTime: {
      // 剩余时间 (s) remainTime='批阅/自改'(批阅状态或者练习自改状态)
      type: String,
      default: function () {
        return ''
      }
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  data() {
    return {
      selfAnswer: this.question.selfAnswer || '',
      isSubmit: !!this.question.selfAnswer,
    };
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        if (oldV.selfAnswer !== newV.selfAnswer || oldV.selfAnswer === null) {
          if (newV.selfAnswer == null) {
            this.selfAnswer = ''
          }
        }
      }
    }
  },
  computed: {
    isRight() {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true;
        } else {
          return selfAnswer === answer.trim();
        }
      };
    },
    hasAnswered () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true;
        }
      };
    },
  },
  mounted () {
    this.$nextTick(function () {
      if (this.question) {
        this.initTitle()
      }
    })
  },
  methods: {
    // 调取答题卡
    showAnswerSheet () {
      this.$emit('showAnswerSheet')
    },
    initTitle () {
      if(this.isExercises || this.isCollect == 1 || this.isCollect == 2) return;
      let str = '('
      if (this.parent) {
        str = '(本题'
      }
      this.$refs.stem.innerHTML = this.$refs.stem.innerHTML + str + this.question.score + '分)'
    },
    inputSubmit () {
      if(this.isExercises) return;
      let obj = {
        ...this.question,
        num: this.num,
        questionId: this.question.questionId,
        selfAnswer: this.selfAnswer,
      };
      if (!removeAllSpace(obj.selfAnswer)) {
        Toast({
          message: "请选择或填写答案",
        });
      }
      // this.isSubmit = true
      console.log(obj, 'obj')
      this.$emit("change", obj)
    },
    // 批阅对错
    changeSelect (val) {
      console.log('qaVal1',val);
      console.log(this.question.trueFlag,Number(this.question.trueFlag),typeof this.question.trueFlag,'qaVal11',val.trueFlag,typeof val.trueFlag,Number(val.trueFlag));
      if (Number(this.question.trueFlag) !== Number(val.trueFlag) || Number(this.question.trueFlag)===0) {
        console.log('qaVal2', val);
        // return
        this.$emit("changeRemarkRead", val)
      }
    },
    // 打分/更新分数
    updateScore (val) {
      let obj = {
        ...val,
        num: this.num
      }
      console.log(val);
      if (this.parent) {
        console.log(777);
        this.$refs.rightErrorTitle2.updateScore(val.selfScore)
      } else {
        console.log(888);
        this.$refs.rightErrorTitle.updateScore(val.selfScore)
      }
      console.log(999);
      // return 
      this.$emit("updateScore", obj)
    },
    submit () {
      let obj = {
        ...this.question,
        num: this.num,
        questionId: this.question.questionId,
        selfAnswer: this.selfAnswer
      }
      if (!removeAllSpace(obj.selfAnswer)) {
        Toast({
          message: '请选择或填写答案'
        })
        return
      }
      this.isSubmit = true
      this.$emit('change', obj)
    },
    // 判断是否在专项练习下做了题
    practiceEvent() {
      let bool = this.isExercises && this.question.selfAnswer ? true : false;
      return bool;
    },
    specialEvent() {
      let bool = this.isExercises && this.question.selfAnswer && !this.question.parentId;
      return bool;
    }
  },
};
</script>

<style lang="scss">
.question-question-answer-exam {
  .stem {
    display: block;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 28px;
    p {
      margin: 0;
      padding: 0;
      color: #333;
      font-size: 16px;
      line-height: 26px;
    }
    span {
      color: #333;
      font-size: 16px;
      line-height: 26px;
    }
    p[block-img] {
      text-align: center;
      img {
        width: 70vw !important;
        height: auto;
        border: 5px;
      }
    }
    img {
      max-width: 100%;
      height: 40px;
      vertical-align: middle;
    }
  }
}
</style>

<style lang="scss" scoped>
.question {
  width: 100%;
  // height: calc(100vh - 58px - 30px);
  box-sizing: border-box;
  padding: 17px 15px 15px 15px;
  overflow: auto;
  .title {
    color: #333;
    font-size: 16px;
    line-height: 26px;
    .kinds {
      display: inline-block;
      line-height: 17px;
      color: #fff;
      text-align: center;
      background: #18a6f3;
      border-radius: 8px 8px 0 8px;
      margin-right: 16px;
      padding: 2px 6px;
      font-size: 12px;
    }
    .stem {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #363840;
      line-height: 28px;
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
    }
    .score {
      color: #999;
    }
  }
  .img {
    width: 80%;
    height: auto;
    display: block;
    margin: 20px auto;
  }
  .content {
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    // border: 1px solid #efefef;
    overflow: hidden;
    .van-cell {
      border-radius: 10px;
      border: 1px solid #EFEFEF;
      // margin-bottom: 10px;
    }
    .bg_gary {
      background: #EFEFEF;
    }
    .submit {
      display: block;
      margin: 20px auto;
    }
    .night {
      ::v-deep .van-field__control {
        color: #ddd;
      }
    }
  }
  .analysis {
    // margin: 30px 15px 70px;
    // padding: 14px 18px;
    font-size: 15px;
    color: #333;
    // background: #eee;
    border-radius: 3px;
    line-height: 25px;
    overflow: hidden;
    word-wrap: break-word;
    span {
      ::v-deep img {
        width: 100%;
      }
      color: #999;
    }
    .anly {
      text-indent: 30px;
    }
  }
}
</style>
