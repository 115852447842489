<!--
 * 答题卡
 -->
<template>
  <div class="modal" @click.self="close">
    <div class="answer-sheet" :style="`background:${userSetting.colorModeExam=='day'?'':'#fff'}`">
      <div class="sheetHead">
        <div>答题卡</div>
        <van-icon name="cross" @click.self="close"/>
      </div>
      <div class="topics" v-if="remainTime && isCollect < 1">
        <template v-if="remainTime == '批阅/自改' || remainTime == '预览' || remainTime == '专项练习'">
          <div class="answer">
            <span class="right">对</span>{{ rightNum() }} 道
          </div>
          <div class="answer">
            <span class="error">错</span>{{ errorNum() }} 道
          </div>
          <!-- <div class="answer">
            <span class="answered">已</span>{{ answerCardList.length - rightNum()- errorNum() }} 道
          </div> -->
        </template>
        <template v-else>
          <div class="answer">
            <span class="answered">已</span>{{ answeredSum() }} 道
          </div>
        </template>
        <div class="answer" v-if="remainTime != '预览'">
          <template  v-if="remainTime == '批阅/自改'">
            <span>未</span>{{ answerCardList.length - rightNum()- errorNum() }} 道
          </template>
          <template v-else>
            <span>未</span>{{ answerCardList.length -answeredSum() }} 道
          </template>
        </div>
        <div class="clear" @click="submit" v-show="false">
          <img src="@/assets/img/paper.png" alt="">交卷
        </div>
      </div>
      <div class="paper-item" v-else>
        <div class="paper-item-title">题型选择</div>
        <div class="paper-item-module">
          <div :class="['paper-type', isActive == index+1 ? 'activeItem':'']" v-for="(item, index) in paperData" :key="index" @click="activeEvent(index+1)">{{item.name}}</div>
        </div>
      </div>
      <div class="t-items">
        <div class="item-box" v-for="(typeItem, typeIndex) in typeList" :key="typeIndex">
          <div class="itemTitle"><span class="column"></span>{{ $common.getQuestionType[typeItem] }}</div>
          <div class="itemContent">
            <template v-for="(item, index) in answerCardList">
              <div class="t-item" v-if="item.typeId==typeItem" :key="index" :class="{ 'right': item.trueFlag==1, 'error': item.trueFlag==0,'none': item.trueFlag==-1,'answered': remainTime != '预览'&& remainTime != '批阅/自改' && remainTime != '专项练习' && isCollect < 1?changeStatus(item):false, 'selecting': questionIndex == index && isCollect >= 1 }" @click="toCurrentExam(index)">
                <template v-if="isCollect < 1">
                  {{ index + 1 }}
                </template>
                <template v-else>
                  {{ page > 1 ? (page - 1) * pageSize + index + 1 : index + 1 }}
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="paging-area" v-if="answerCardList.length > 0 && isCollect >= 1">
        <van-button @click="nextEvent('last')" icon="arrow-left" type="info" round :disabled="page == 1"></van-button>
        <van-button @click="nextEvent('next')" icon="arrow" type="info" round :disabled="answerCardList.length < pageSize"></van-button>
      </div>
      <div class="sheetBtn" v-if="remainTime != '预览' && this.isCollect < 1">
        <!-- <van-button type="default" v-if="isExercises" @click="again">重新答题</van-button> -->
        <van-button plain type="primary" @click="submit">{{remainTime == '批阅/自改' ? '提交批改':'提交'}}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    sumed: {
      type: Number,
      default: function () {
        return 0
      }
    },
    // answeredSum: { // 已答
    //   type: Number,
    //   default: function () {
    //     return 0
    //   }
    // },
    leftSum: { // 未答
      type: Number,
      default: function () {
        return 0
      }
    },
    answerCardList: {
      type: Array,
      default: function () {
        return []
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    remainTime: {
      // 剩余时间 (s) remainTime='批阅/自改'(批阅状态或者练习自改状态)
      type: String,
      default: function () {
        return ''
      }
    },
    // 题目类型
    paperType: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    },
    isActive: {
      type: Number,
      default: function () {
        return 1
      }
    }
  },
  data () {
    return {
      examTotal: this.answerCardList.length,
      typeList: [], // 题目类型列表
      paperData: [
        {
          name: '单选题',
          type: 1
        },
        {
          name: '多选题',
          type: 2
        },
        {
          name: '不定项选择题',
          type: 3
        },
        {
          name: '判断题',
          type: 4
        },
        {
          name: '填空题',
          type: 5
        },
        {
          name: '问答题',
          type: 6
        },
        {
          name: '材料分析题',
          type: 7
        },
        {
          name: '配伍题',
          type: 8
        },
      ],
      questionIndex: 0,
      page: 1,
      pageSize: 30
    }
  },
  created () {
    this.getTypeList()
    this.page = this.$store.state.page
    this.pageSize = this.$store.state.pageSize
    if(this.isCollect >= 1) this.questionIndex = this.$store.state.questionIndex
  },
  watch: {
    answerCardList: {
      deep: true,
      handler (newV, oldV) {
        this.answerCardList = newV
        if(this.isCollect >= 1) this.getTypeList()
      }
    },
    '$store.state.page'(newVal, oldVal) {
      this.page = newVal
    }
  },
  computed: {
    ...mapGetters({ userSetting: 'userSetting' }),
    rightNum () {
      return function () {
        let num = 0
        // console.log(this.answerCardList,'this.answerCardListthis.answerCardListthis.answerCardListthis.answerCardListthis.answerCardList')
        for (let i in this.answerCardList) {
          if (this.answerCardList[i].trueFlag == 1) {
            num++
          }
        }
        return num
      }
    },
    errorNum () {
      return function () {
        let num = 0
        for (let i in this.answerCardList) {
          if (this.answerCardList[i].trueFlag == 0) {
            num++
          }
        }
        return num
      }
    },
    neverNum () {
      return function () {
        let num = 0
        for (let i in this.answerCardList) {
          if (this.answerCardList[i].trueFlag == -1) {
            num++
          }
        }
        return num
      }
    },
    answerStatus () {
      return function (item) {
        const userAnswer = item.userAnswer
        if (this.isExercises) {
          return userAnswer ? ((item.rightFlag === 1 || item.rightFlag === '1') ? '' : 'error') : 'none'
        } else {
          if (userAnswer) return 'answered'
          return userAnswer ? 'answered' : 'none'
        }
      }
    }
  },
  methods: {
    activeEvent(val) {
      this.questionIndex = 0
      this.$store.state.questionIndex = 0
      this.$store.state.page = 1
      this.$emit('changeQuestionType', val)
    },
    getTypeList () {
      this.answerCardList.forEach((item, index) => {
        if(this.isCollect >= 1) this.typeList = []
        if (this.typeList.length > 0) {
          for (let i in this.typeList) {
            if (this.typeList[i] == item.typeId) {
              break
            } else if (i == this.typeList.length-1) {
              this.typeList.push(item.typeId)
            }
          }
        } else {
          this.typeList.push(item.typeId)
        }
      })
      // console.log(this.typeList, 'typeList')
    },
    // 已答数目
    answeredSum () {
      let num = 0
      this.answerCardList.forEach(item => {
        if (item.typeId < 7) {
          if (item.selfAnswer && item.selfAnswer !== '[]' && item.selfAnswer !== []) {
            // if (item.typeId == 6) {
            //   if (item.selfAnswer.length > 4) {
            //     num++
            //   }
            // } else {
            //   }
            num++
          }
        } else if (item.childrenList.length > 0) {
          let num2 = 0
          item.childrenList.forEach(item2 => {
            if (item2.selfAnswer && item2.selfAnswer !== '[]' && item2.selfAnswer !== []) {
              if (item2.typeId == 6) {
                // if (item2.selfAnswer.length > 4) {
                //   }
                num2++
              } else {
                num2++
              }
            }
          })
          if (num2 === item.childrenList.length) {
            num++
          }
        }
      });
      return num
    },
    // leftSum () {
    //   let num = this.answeredSum
    //   return this.answerCardList.length - num
    //   // return this.answerCardList.filter((item) => !item.selfAnswer).length
    // },
    // 改变已填未填状态
    changeStatus (item) {
      // item.selfAnswer
      if (item.childrenList.length > 0) {
        // console.log('有小题2')
        // console.log(item,'item');
        let flag = false
        let num = 0
        for (let i in item.childrenList) {
          if (item.childrenList[i].selfAnswer !== [] && item.childrenList[i].selfAnswer !== null && item.childrenList[i].selfAnswer !== '') {
            // console.log('大题有答案', i)
            // console.log(item.childrenList[i].selfAnswer)
            // if (item.childrenList[i].typeId === 6) {
            //   if (item.childrenList[i].selfAnswer.length >= 5) {
            //     ++num
            //   }
            // } else
            if (item.childrenList[i].typeId === 2) {
              if (item.childrenList[i].selfAnswer !== [] && item.childrenList[i].selfAnswer !== null && item.childrenList[i].selfAnswer !== '[]' && item.childrenList[i].selfAnswer !== '') {
                ++num
              }
            } else {
              ++num
            }
            if (num === item.childrenList.length) {
              flag = true
              item.selfAnswer = true
            }
          }
        }
        return flag
      } else {
        // if (item.typeId === 6) {
        //   if (item.selfAnswer !== null && item.selfAnswer.length >= 5) {
        //     return item.selfAnswer
        //   }
        // } else
        if (item.typeId === 5) {
          // console.log(JSON.parse(item.selfAnswer))
          if(item.selfAnswer == '') return false;
          let self = JSON.parse(item.selfAnswer)
          if (item.selfAnswer !== null && self.length > 0) {
            for (let i in self) {
              if (self[i].length > 0) {
                return item.selfAnswer
              }
            }
          }
        } else if (item.typeId === 2) {
          // console.log(item.selfAnswer, 'sea')
          if (item.selfAnswer !== null && item.selfAnswer !== [] && item.selfAnswer !== '[]') {
            return item.selfAnswer
          }
        } else {
          return item.selfAnswer
        }
      }
    },
    // 重新
    again () {
      this.$emit('again')
    },
    submit () {
      this.$emit('submit')
    },
    close () {
      this.$emit('close')
    },
    toCurrentExam (index) {
      this.$store.state.questionIndex = index
      this.$emit('to-current-exam', index)
    },
    nextEvent(type) {
      this.$emit('nextEvent', type)
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal {
    width: 100%;
    height: 100vh;
    background: rgba($color: #000000, $alpha: .2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
  }
  .answer-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: max-content;
    background: #fff;
    box-sizing: border-box;
    border-radius: 20px 20px 0px 0px;
    padding-top: 24px;
    .sheetHead{
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #363840;
      border-bottom: 1px solid #F9F9F9;
      padding-bottom: 25px;
    }
    .topics {
      padding: 15px;
      font-size: 13px;
      color: #999;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .answer {
        float: left;
        margin-right: 26px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        span {
          height: 22px;
          width: 22px;
          background: #EFEFEF;
          border-radius: 50%;
          line-height: 22px;
          text-align: center;
          &.right{
            color: #1CB74D;
          }
          &.error{
            color: #E21A1A;
          }
          &.answered{
            color: #1A7EE2;
          }
        }
        img {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 8px;
          border-radius: 2px;
          box-sizing: border-box;
        }
      }
      img {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 7px;
      }
      .num {
        color: #333;
      }
      .reback {
        display: inline-block;
        color: #7AB703;
        margin-left: 23px;
      }
      .setting {
        width: 18px;
        height: 18px;
        float: right;
      }
    }
    .paper-item {
      padding: 15px;
      .paper-item-title {
        color: #363840;
        font-size: 14px;
      }
      .paper-item-module {
        display: flex;
        flex-wrap: wrap;
      }
      .paper-type {
        display: inline-block;
        margin: 10px;
        padding: 3px 6px;
        color: #1A7EE2;
        border: 1px solid #1A7EE2;
        border-radius: 6px;
      }
      .activeItem {
        color: #fff;
        background-color: #1A7EE2;
      }
    }
    .topics1 {
      padding: 8px 15px 18px 15px;
      font-size: 13px;
      color: #333;
      border-bottom: 1px solid #eaeaea;
      &.night{
          border-bottom: 1px solid #2c2c2c;
      }
      overflow: hidden;
      .answer {
        float: left;
        margin-right: 26px;
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 8px;
          border: 1px solid #c6c6c6;
          border-radius: 2px;
          box-sizing: border-box;
        }
        img {
          display: inline-block;
          width: 13px;
          height: 13px;
          margin-right: 8px;
          border-radius: 2px;
          box-sizing: border-box;
        }
        .answered {
          border: 1px solid #18A6F3;
        }
      }
      .clear {
        float: right;
        display: flex;
        align-items: center;
        img {
          display: inline-block;
          margin-right: 7px;
          width: 12px;
          height: 12px;
        }
      }
    }
    .t-items {
      padding: 0 15px 30px;
      overflow-y: auto;
      height: 310px;
      .item-box {
        .itemTitle{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 20px;
          .column {
            width: 3px;
            height: 12px;
            background: #1A7EE2;
            margin-right: 5px;
            display: inline-block;
          }
        }
        .itemContent{
          display: grid;
          grid-template-columns: repeat(auto-fill, 32px);
          grid-gap: 10px 20px;
          margin: 15px 0 25px;
          .t-item {
            width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            color: #363840;
            &.night{
              color: #c6c6c6;
            }
            text-align: center;
            border: 1px solid #EFEFEF;
            border-radius: 5px;
            box-sizing: border-box;
            &:nth-child(7n) {
              margin-right: 0;
            }
            &.none {
              border-color: #c6c6c6;
            }
            &.error {
              border-color: #E21A1A;
            }
            &.right {
              border-color: #7AB703;
            }
            &.answered {
              border-color: #1A7EE2;
            }
            &.selecting {
              border-color: #1A7EE2;
            }
          }
        }
      }
    }
    .paging-area {
      position: absolute;
      bottom: 50px;
      // left: 50%;
      // transform: translateX(-50%);
      width: 100%;
      text-align: center;
      & > button {
        margin: 0 64px;
      }
    }
    .sheetBtn{
      padding: 0 0 43px;
      display: flex;
      justify-content: center;
      .van-button:last-child{
        margin-left: 20px;
        color: #1A7EE2;
        border: 1px solid #1A7EE2;
        background: #FFF;
      }
      .van-button{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        width: 120px;
        height: 40px;
        background: #F9F9F9;
        border-radius: 4px;
        border: none;
      }
    }
  }
</style>
