<template>
  <div class="question-analysis question-analysis-exam">
    <rightErrorTitle
      v-if="(!select && question.trueFlag != -1) || isShowRightError"
      ref="rightErrorTitleMax"
      :select='isSelect'
      :showRight="showRight() || isShowRight"
      :examTotal='examTotal'
      :num='num'
      :isCollect="isCollect"
      @showAnswerSheet='showAnswerSheet'
    ></rightErrorTitle>
    <div class="title">
      <!-- <span class="kinds">材料分析题</span> -->
      <span>{{ this.num }}.({{question.typeId==7?'材料分析题':'配伍题'}}) </span>
      <div
        ref="stem"
        v-html="question.stem"
        class="stem"
      ></div>
    </div>
    <div
      class="question-tip"
    >
      请认真阅读上述材料，完成以下题目：
    </div>
    <div class="content" v-for="(item, index) in childrenList" :key="index">
      <single-choice
        v-if="item.typeId == 1"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :isCollect="isCollect"
        :select="select"
        :parent="true"
        @change="answer"
      ></single-choice>
      <multiple-choice
        v-else-if="item.typeId == 2|| item.typeId == 3"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :isCollect="isCollect"
        :parent="true"
        :select="select"
        @change="answer"
      ></multiple-choice>
      <!-- <Uncertain
        v-else-if="item.typeId == 3"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :parent="true"
        :select="select"
        @change="answer"
      ></Uncertain> -->
      <judge
        v-else-if="item.typeId == 4"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :isCollect="isCollect"
        :parent="true"
        :select="select"
        @change="answer"
      ></judge>
      <Fill
        v-else-if="item.typeId == 5"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :isCollect="isCollect"
        :parent="true"
        :select="select"
        :remainTime="remainTime"
        @change="answer"
        @updateScore='updateScore'
        @changeRemarkRead='changeSelect'
      ></Fill>
      <question-answer
        v-else-if="item.typeId == 6"
        :question="item"
        :num="index + 1"
        :isExercises="isExercises"
        :isCollect="isCollect"
        :parent="true"
        :select="select"
        :remainTime="remainTime"
        @change="answer"
        @updateScore='updateScore'
        @changeRemarkRead='changeSelect'
      ></question-answer>
    </div>
    <!-- <van-button
      v-if="!isSubmit && isExercises"
      class="submit"
      round size="small"
      color="#18A6F3"
      type="info"
      @click="submit">提 交</van-button>
    <van-button
      v-if="isSubmit && isExercises"
      class="submit"
      round size="small"
      color="#aaa"
      type="info">已提交</van-button> -->
    <!-- 得分 -->
    <div class="updateScoreMax" v-if="remainTime == '批阅/自改' || remainTime == '预览' || isShowScore">
      <van-field
        v-model="areaScore"
        type="number"
        label="总得分："
        :disabled='true'
      />
    </div>
  </div>
</template>

<script>
import SingleChoice from "./singleChoice";
import MultipleChoice from "./multipleChoice";
import Uncertain from "./uncertain";
import Fill from "./fill";
import Judge from "./judge";
import QuestionAnswer from "./questionAnswer";
import rightErrorTitle from '../rightErrorTitle'
export default {
  components: {
    SingleChoice,
    MultipleChoice,
    Uncertain,
    Fill,
    Judge,
    QuestionAnswer,
    rightErrorTitle
  },
  mounted () {
    this.$nextTick(function () {
      if (this.question) {
        this.initTitle()
        this.selfScoreAll()
      }
    })
  },
  computed: {
    childrenList: function () {
      return this.question.childrenList.filter(
        (item) =>
          item.typeId === 1 ||
          item.typeId === 2 ||
          item.typeId === 3 ||
          item.typeId === 4 ||
          item.typeId === 5 ||
          item.typeId === 6
      );
    },
    showRight () {
      return function () {
        if (this.question.trueFlag== 0) {
          return false
        } else if (this.question.trueFlag== 1) {
          return true
        }
      }
    }
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        console.log(newV, 'newMax')
        // if (oldV.selfAnswer !== newV.selfAnswer) {
        //   if (newV.selfAnswer == null) {
        //     this.selfAnswer = ''
        //   }
        // }
        if(this.isExercises) {
          this.showAreaScore(newV)
        } else {
          this.selfScoreAll()
        }
      }
    }
  },
  props: {
    question: {
      type: Object,
      default: function () {
        return {};
      },
    },
    num: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    select: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    remainTime: {
      // 剩余时间 (s) remainTime='批阅/自改'(批阅状态或者练习自改状态)
      type: String,
      default: function () {
        return ''
      }
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  data () {
    return {
      areaScore: 0,
      isShowScore: false,
      isShowRightError: false,
      isShowRight: false,
      isSelect: false
    }
  },
  methods: {
    // 调取答题卡
    showAnswerSheet () {
      this.$emit('showAnswerSheet')
    },
    selfScoreAll () {
      console.log('selfScoreAll');
      if (this.question.childrenList.length > 0) {
        // 计算非客观题分数
        this.areaScore = 0
        for (let i in this.question.childrenList) {
          if (this.question.childrenList[i].selfScore) {
            this.areaScore = Number(this.areaScore) + Number(this.question.childrenList[i].selfScore)
          }
          // if (this.question.childrenList[i].trueFlag != -1) {
          // }
        }
      }
      console.log(this.areaScore,'selfScoreAll',this.question)
    },
    initTitle () {
      if(this.isExercises || this.isCollect == 1 || this.isCollect == 2) return;
      this.$refs.stem.innerHTML = this.$refs.stem.innerHTML + '(' + this.question.score + '分)'
    },
    answer (val) {
      // console.log(val, 'valQ')
      // console.log(this.question, 'this.question')
      var qMain = this.question
      qMain.childrenList[val.num - 1] = val
      console.log(qMain, 'qMain')
      this.$emit('change', {
        ...this.question,
        num: this.num,
        miniNum: val.num - 1,
        parentQuestion: this.question
      })
    },
    onChange (e) {
      // this.$nextTick(() => {
      //   this.$emit('question-index', e)
      // })
    },
    // 批阅对错
    changeSelect (val) {
      let obj = {
        ...val,
        num: this.num,
        miniNum: val.num - 1,
        parentTypeId: this.question.typeId,
        typeId: val.typeId,
        parentId: this.question.questionId
      }
      this.$emit("changeRemarkRead", obj)
    },
    // 打分/更新分数
    updateScore (val) {
      let obj = {
        ...val,
        num: this.num,
        miniNum: val.num - 1,
        parentTypeId: this.question.typeId,
        typeId: this.question.typeId,
        parentId: this.question.questionId,
        parentQuestion: this.question
      }
      console.log(val, 'updateScoreMax');
      this.selfScoreAll()
      this.areaScore += Number(val.selfScore)
      console.log(this.$refs);
      // this.$refs.rightErrorTitleMax.updateScore(this.areaScore)
      this.$emit("updateScore", obj)
    },
    showAreaScore(data) {
      let index = 0;
      let flag = true;
      data.childrenList.forEach(item => {
        if(item.trueFlag !== null) {
          index++;
          if(item.trueFlag === 0) flag = false
        }
      });
      // console.log(index, data.childrenList, index == data.childrenList.length, flag, 963)
      if(index == data.childrenList.length) {
        this.isShowRightError = true
        this.isShowRight = flag
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.question-analysis-exam {
  .stem {
    display: block;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 28px;
    p {
      margin: 0;
      padding: 0;
      color: #333;
      font-size: 16px;
      line-height: 26px;
    }
    span {
      color: #333;
      font-size: 16px;
      line-height: 26px;
    }
    p[block-img] {
      text-align: center;
      img {
        width: 70vw !important;
        height: auto;
        border: 5px;
      }
    }
    img {
      max-width: 100%;
      height: 40px;
      vertical-align: middle;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .updateScoreMax .van-cell .van-cell__value .van-field__body .van-field__control{
  width: 58px;
  height: 32px;
  color: #363840;
  -webkit-text-fill-color: #363840;
  // background: #EFEFEF;
  border: 1px solid #EFEFEF;
  border-radius: 4px;
  text-align: center;
  background: #EFEFEF;
}
::v-deep .updateScoreMax .van-cell__title.van-field__label{
  width: auto;
}
::v-deep .updateScoreMax .van-cell__title.van-field__label span{
  font-size: 16px;
  color: #363840 !important;
}
.question-analysis {
  .question-tip {
    color: #b2b2b2;
    margin-top: 40px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 28px;
  }
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 58px);
  overflow: auto;
  padding: 17px 15px 55px 15px;
  .title {
    color: #333;
    font-size: 16px;
    line-height: 26px;
    .kinds {
      display: inline-block;
      line-height: 17px;
      color: #fff;
      text-align: center;
      background: #18a6f3;
      border-radius: 8px 8px 0 8px;
      margin-right: 16px;
      padding: 2px 6px;
      font-size: 12px;
    }
    .score {
      color: #999;
    }
  }
  .img {
    width: 80%;
    height: auto;
    display: block;
    margin: 20px auto;
  }
  .submit {
    display: block;
    margin: 0 auto;
  }
}

</style>
