<template>
  <div class="font-size-adjuster">
    <div class="mini-A">A</div>
    <div class="body">
      <div class="text-wrap">
        <span v-for="(item, index) in multipleList" :key="index">{{item.name}}</span>
      </div>
      <div class="bar-wrap">
        <div class="vertical-bar" v-for="(item, index) in multipleList" :key="index"></div>
      </div>
      <van-slider v-model="progress" :bar-height="1" active-color="#cacaca" inactive-color="#cacaca" @change="change" :step="step" :min="min" :max="max" >
        <!-- <template #button>
          <div class="custom-button">
          </div>
        </template> -->
  </van-slider>
    </div>
    <div class="small-A">A</div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      progress: 0,
      max: 0,
      min: 0
    }
  },
  props: {
    step: {
      type: Number,
      default: 1
    },
    multipleList: {
      type: Array,
      default: () => ([
        {
          multiple: 0.8,
          name: '小'
        },
        {
          multiple: 1,
          name: '标准',
          default: true
        },
        {
          multiple: 1.2,
          name: '大'
        },
        {
          multiple: 1.5,
          name: '超大'
        }
      ])
    }
  },
  created () {
    this.max = this.multipleList.length - 1
    this.multipleList.forEach((item, index) => {
      if (item.default === true) {
        this.progress = index
      }
    })
  },
  methods: {
    change (value) {
      this.$emit('change', this.multipleList[value])
    }
  }
}
</script>
<style lang="scss">
  .van-slider__button{
    background-color:rgb(24, 166, 243)!important;
  }
</style>
<style lang="scss" scoped>
.mini-A {
  font-size: 14px;
  color: #333333;
  margin-top: 20px;
  position: relative;
  top: 5px;
}
.small-A {
  font-size: 22px;
  color: #333333;
  position: relative;
  top: 5px;
}
.font-size-adjuster {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.body {
  width: 80%;
}
.bar-wrap {
  display: flex;
  justify-content: space-between;
  top: 7Px;
  position: relative;
}
.text-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  // top: 8Px;
  // position: relative;
  span {
    font-size: 12px;
    color: #3d3d3d;
  }
}
.vertical-bar {
  height: 14Px;
  width: 1Px;
  background: #cacaca;
  z-index: 0;
}
</style>
