<!--
 * @Date: 2020-02-06 14:19:12
 * @LastEditors: Please set LastEditors
 * @Author: xw
 * @LastEditTime: 2021-03-30 14:38:18
 * @Description: 多选题
 -->
<template>
  <div class="question question-multipleChoice-exam" :style="{ height: parent ? 'auto' : 'calc(100vh - 58px - 70px)' }">
    <rightErrorTitle v-if="(!select && !parent) || (isExercises && isSubmit && !question.parentId)"
      :showRight='showRight()'
      :examTotal='examTotal'
      :num='num'
      :isCollect="isCollect"
      @showAnswerSheet='showAnswerSheet'
    ></rightErrorTitle>
    <div class="title">
      <span v-if="parent">({{ this.num }}).(多选题) </span>
      <span v-else>{{ this.num }}.(多选题) </span>
      <span
        class="stem"
        ref="stem"
        v-html="question.stem"
      ></span>
    </div>
    
    <div class="content">
      <van-checkbox-group
        v-model="selfAnswer"
        @change="multipleChoice"
        :disabled="!select"
      >
        <van-checkbox
          v-for="(item, index) in formatConversion(question.optionContents)"
          :key="index"
          :name="item.name"
          :class="[getMultipleResult(selfAnswer, item.name, answer),]"
          :disabled="isSubmit"
        >
          <div
            slot="icon"
            :class="[
              'checkbox',
              getMultipleResult(selfAnswer, item.name, answer),
            ]"
          >
            {{ item.name }}
          </div>
          <span
            v-if="!!item.content"
            class="option-content"
            v-html="item.content"
          ></span>
        </van-checkbox>
      </van-checkbox-group>
      <van-button
        v-if="!isSubmit && isExercises"
        class="submit"
        round size="small"
        color="#18A6F3"
        type="info"
        @click="submit">提 交</van-button>
      <van-button
        v-if="isSubmit && isExercises"
        class="submit"
        round size="small"
        color="#aaa"
        type="info">已提交</van-button>
    </div>
    <!-- 答题解析区 -->
    <answerArea
      ref="answerArea"
      v-if="!select || (isExercises && isSubmit)"
      :question='question'
      :isExercises='isExercises'
      :isCollect="isCollect"
    ></answerArea>
    <!-- <div class="btn-area" v-if="isExercises && (question.typeId == 2 || question.typeId == 3)">
      <van-button type="info">提交</van-button>
    </div> -->
  </div>
</template>

<script>
import _ from "lodash";
import { Toast } from "vant";
import rightErrorTitle from '../rightErrorTitle'
import answerArea from '../answerArea'
export default {
  components: {
    rightErrorTitle,
    answerArea
  },
  props: {
    question: {
      type: Object,
      default: function () {
        return {};
      },
    },
    num: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    select: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    parent: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      if (this.question) {
        this.initTitle()
      }
    })
  },
  data () {
    const selfAnswer = this.question.selfAnswer || "";
    const answer = this.question.answer || "";
    return {
      selfAnswer: selfAnswer ? JSON.parse(selfAnswer) : [], // van-checkbox-group绑定的v-modal为数组
      answer: answer ? JSON.parse(answer) : [], // van-checkbox-group绑定的v-modal为数组
      result: "",
      os: "",
      isSubmit: !!selfAnswer,
    };
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        if (oldV.selfAnswer !== newV.selfAnswer && newV.selfAnswer === null) {
          if (newV.selfAnswer == null) {
            this.$set(this, "selfAnswer", [])
            this.os = "again"
            // this.selfAnswer = []
          }
        }
      },
    },
  },
  computed: {
    answerStr () {
      try {
        const answerArr = JSON.parse(this.question.answer).join("")
        return answerArr;
      } catch (e) {
        return this.question.answer;
      }
    },
    isRight () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true;
        } else {
          // eslint-disable-next-line
          let answerStr = answer.replace(/[,|\[|\]|\"|\\]/g, "");
          return selfAnswer.join("") === answerStr;
        }
      };
    },
    hasAnswered () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true;
        }
      }
    },
    showRight () {
      return function () {
        if (this.question.trueFlag== 0) {
          return false
        } else if (this.question.trueFlag== 1) {
          return true
        }
        // if (this.selfAnswer) {
        //   for (let i in this.answer) {
        //     for (let j in this.selfAnswer) {
        //       if (this.answer[i] !== this.selfAnswer[j]) {
        //         return false
        //       }
        //     }
        //     if (i == this.answer.length - 1) {
        //       return true
        //     }
        //   }
        // } else {
        //   return false
        // }
      }
    },
    getMultipleResult () {
      return function (selfAnswer, name, answer) {
        // let result = selfAnswer.includes(name) ? "right" : "";
        let result = ''
        if (!this.select || (this.isExercises && this.isSubmit)) {
          let newArr = new Set(selfAnswer.concat(answer))
          // console.log(Array.from(newArr),'newArr');
          for (let item of newArr) {
            if (name === item) {
              for (let i in answer) {
                if (answer[i] === item) {
                  result = 'right'
                  break
                  // continue
                } else if (i == answer.length - 1 && answer[i] !== item) {
                  result = 'error'
                }
              }
            }
            // } else if (name === answer) {
            //   result = 'right'
            // }
          }
        } else {
          if (selfAnswer.length>0) {
            for (let i in selfAnswer) {
              if (selfAnswer[i]==name) {
                result = 'select'
                break
              }
            }
          }
        }
        return result;
      };
    },
    formatConversion () {
      return function (str) {
        if (str) {
          return JSON.parse(str);
        } else {
          return [];
        }
      };
    },
  },
  methods: {
    // 调取答题卡
    showAnswerSheet () {
      this.$emit('showAnswerSheet')
    },
    initTitle () {
      if(this.isExercises || this.isCollect == 1 || this.isCollect == 2) return;
      let str = '('
      if (this.parent) {
        str = '(本题'
      }
      this.$refs.stem.innerHTML = this.$refs.stem.innerHTML + str + this.question.score + '分)'
    },
    multipleChoice (e) {
      console.log(e, "<<<");
      this.result = _.cloneDeep(this.selfAnswer);
      // this.isSubmit = false
      if (this.result.length === 0) {
        Toast({
          message: "请选择或填写答案",
        });
        // return;
      }
      // if (this.result && this.result.length < 2) {
      //   Toast({
      //     message: ''
      //   })
      //   return
      // }
      let obj = {
        ...this.question,
        num: this.num,
        questionId: this.question.questionId,
        selfAnswer: JSON.stringify(this.result.sort()),
      };
      // this.$nextTick(() => {
      //   this.isSubmit = true;
      // });
      if (this.os !== "again") {
        this.$emit("change", obj);
      } else {
        this.os = "";
      }
    },
    submit () {
      console.log(this.result, '<<<')
      console.log(this.isSubmit, '000')
      if (this.result.length === 0) {
        Toast({
          message: '请选择或填写答案'
        })
        return
      }
      // if (this.result && this.result.length < 2) {
      //   Toast({
      //     message: ''
      //   })
      //   return
      // }
      let obj = {
        ...this.question,
        questionId: this.question.questionId,
        selfAnswer: JSON.stringify(this.result.sort())
      }
      this.$nextTick(() => {
        this.isSubmit = true
      })
      this.$emit('change', obj)
    }
  },
};
</script>

<style lang="scss">
.question-multipleChoice-exam {
  .stem {
    // display: block;
    display: contents;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 28px;
    p {
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
    span {
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
    }
    p[block-img] {
      text-align: center;
      img {
        width: 70vw !important;
        height: auto;
        border: 5px;
      }
    }
    img {
      max-width: 100%;
      height: 40px;
      vertical-align: middle;
    }
  }
  .option-content {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    p {
      margin: 0;
      padding: 0;
      // color: #333 !important;
      // font-size: 15px !important;
      // line-height: 26px !important;
    }
    span {
      // color: #333 !important;
      // font-size: 15px !important;
      // line-height: 26px !important;
    }
    p[block-img] {
      text-align: left;
      img {
        width: 30vw !important;
        height: auto;
      }
    }
    img {
      height: 40px;
      vertical-align: middle;
    }
  }
  .quesion-analysis {
    display: block;
    p {
      margin: 0;
      padding: 0;
      // color: #333 !important;
      // font-size: 16px !important;
      // line-height: 26px !important;
    }
    span {
      // color: #333 !important;
      // font-size: 16px !important;
      // line-height: 26px !important;
    }
    p[block-img] {
      text-align: center;
      img {
        width: 60vw !important;
        height: auto;
        border: 5px;
      }
    }
    img {
      height: 40px;
      vertical-align: middle;
    }
  }
}
</style>

<style lang="scss" scoped>
.question {
  width: 100%;
  // height: calc(100vh - 58px - 30px);
  box-sizing: border-box;
  padding: 17px 15px 15px 15px;
  overflow: auto;
  .title {
    color: #333;
    font-size: 16px;
    line-height: 26px;
    .kinds {
      display: inline-block;
      line-height: 17px;
      color: #fff;
      text-align: center;
      background: #18a6f3;
      border-radius: 8px 8px 0 8px;
      margin-right: 16px;
      padding: 2px 6px;
      font-size: 12px;
    }
    .score {
      color: #999;
    }
  }
  .img {
    width: 80%;
    height: auto;
    display: block;
    margin: 20px auto;
  }
  .content {
    margin-top: 30px;
    margin-bottom: 30px;
    .select {
      border-color: #1A7EE2 !important;
    }
    .right {
      border-color: #1CB74D !important;
    }
    .error {
      border-color: #E21A1A !important;
    }
    .van-checkbox {
      margin-bottom: 15px;
      color: #333;
      min-height: 38px;
      align-items: baseline;
      -webkit-box-align: inherit !important;
      -webkit-align-items: inherit !important;
      align-items: inherit !important;
      border: 0.025rem solid #efefef;
      border-radius: 10px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1A7EE2;
      line-height: 20px;
      height: auto;
      // ::v-deep .checkbox .van-checkbox__icon {
      //   ::v-deep .checkbox {
      //     height: 100%;
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //   }
      // }
      ::v-deep .checkbox {
        border-right: 1px solid rgb(239, 239, 239) !important;
      }
      ::v-deep .van-checkbox__label {
        display: flex;
        align-items: center;
        margin: 0.133333rem;
      }
      .select {
        background: #1A7EE2;
        color: #fff;
      }
      .right {
        background: #1CB74D;
        color: #fff;
      }
      .error {
        background: #E21A1A;
        color: #ffffff;
      }
      ::v-deep .van-checkbox__icon {
        height: auto;
      }
      .checkbox {
        height: 100% !important;
        width: 40px;
        min-height: 40px;
        line-height: 40px;
        font-size: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        // color: #1A7EE2;
        border: none;
        // margin: 0 10px 0 2px;
        // border-radius: 50%;
        // box-shadow: 0px 2px 4px 0 rgba($color: #000000, $alpha: 0.2);
        // color: #333;
        // &.right {
        //   background: #1a7ee2;
        //   border-color: #1a7ee2;
        //   color: #fff !important;
        // }
      }
    }
    .van-checkbox__icon--checked {
      border-color: #1a7ee2;
    }
    // .van-checkbox {
    //   margin-bottom: 15px;
    //   color: #333;
    //   min-height: 38px;
    //   align-items:  flex-start;
    //   .right {
    //     color: #18A6F3;
    //   }
    //   .error {
    //     color: #ff790d;
    //   }
    // }
    // .checkbox {
    //   display: inline-block;
    //   width: 25px;
    //   height: 25px;
    //   line-height: 25px;
    //   margin: 0 10px 0 2px;
    //   text-align: center;
    //   border-radius: 50%;
    //   box-shadow: 0px 2px 4px 0 rgba($color: #000000, $alpha: .2);
    //   // font-size: 15px;
    //   // color: #333;
    //   &.right {
    //     background: #18A6F3;
    //     color: #fff!important;
    //   }
    //   &.error {
    //     background: #ff790d;
    //     color: #fff!important;
    //   }
    // }
    .submit {
      display: block;
      margin: 20px auto;
    }
  }
  .btn-area {
    text-align: center;
  }
  .infoContent {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    .info.selfAnswer {
      span.infoTitle {
      }
      span {
      }
    }
    .info.answer {
      span.infoTitle.underline {
      }
      span {
      }
    }
    .info.analysis {
      span.infoTitle {
      }
      span.quesion-analysis {
      }
    }
    // .analysis {
    //   margin: 30px 15px 70px;
    //   padding: 14px 18px;
    //   font-size: 15px;
    //   color: #333;
    //   background: #eee;
    //   border-radius: 3px;
    //   line-height: 25px;
    //   overflow: hidden;
    //   word-wrap: break-word;
    //   span {
    //     color: #999;
    //   }
    //   .anly {
    //     text-indent: 30px;
    //   }
    // }
  }
}
</style>
