<!--
@Description: 判断题
 -->

<template>
  <div class="question question-judge-exam" :style="{height:(parent? 'auto':'calc(100vh - 58px - 70px)')}">
    <rightErrorTitle v-if="(!select && !parent) || specialEvent()"
      :showRight='question.selfAnswer===question.answer'
      :examTotal='examTotal'
      :num='num'
      :isCollect="isCollect"
      @showAnswerSheet='showAnswerSheet'
    ></rightErrorTitle>
    <div class="title">
      <span v-if="parent">{{num}}.(判断题) </span>
      <span v-else>{{num}}.(判断题) </span>
      <span v-html="question.stem" ref="stem" class="stem"></span>
    </div>
    
    <div class="content">
      <!-- getSingleResult(selfAnswer, true, question.answer)=='select' ? '#1A7EE2' : (getSingleResult(selfAnswer, true, question.answer)==='right' ? '#1CB74D' : '#E21A1A') -->
      <van-radio-group v-model="selfAnswer" direction="horizontal" @change="change" :disabled="!select || practiceEvent()">
        <van-radio name="true" :checked-color="selfAnswer == question.answer ? '#1CB74D' : '#1A7EE2'" :class="getSingleResult(selfAnswer, true, question.answer)">
          <span :class="getSingleResult(selfAnswer, true, question.answer)">正确</span>
        </van-radio>
        <van-radio name="false" :checked-color="selfAnswer == question.answer ? '#1CB74D' : '#1A7EE2'" :class="getSingleResult(selfAnswer, false, question.answer)">
            <template #icon="props" v-if="!select && selfAnswer!==question.answer">
              <van-icon name="cross" />
            </template>
          <span :class="getSingleResult(selfAnswer, false, question.answer)">错误</span>
        </van-radio>
      </van-radio-group>
    </div>
     <!-- 答题解析区 -->
     <answerArea
      ref="answerArea"
      v-if="!select || practiceEvent()"
      :question='question'
      :isExercises='isExercises'
      :isCollect="isCollect"
    ></answerArea>
  </div>
</template>

<script>
import { Toast } from 'vant'
import rightErrorTitle from '../rightErrorTitle'
import answerArea from '../answerArea'
export default {
  components: {
    rightErrorTitle,
    answerArea
  },
  name: 'Judge',
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    select: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    parent: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  data () {
    return {
      selfAnswer: this.question.selfAnswer ? this.question.selfAnswer : '',
      isSubmited: !!this.question.selfAnswer,
      result: ''
    }
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        if (oldV.selfAnswer !== newV.selfAnswer || oldV.selfAnswer === null) {
          if (newV.selfAnswer == null) {
            this.selfAnswer = ''
          }
        }
      }
    }
  },
  computed: {
    isRight () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) { // 未作答
          return true
        } else {
          return String(selfAnswer) === String(answer)
        }
      }
    },
    hasAnswered () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) { // 未作答
          return true
        }
      }
    },
    getSingleResult () {
      return function (selfAnswer, name, answer) {
        let result = ''
        if (!this.select || (this.isExercises && selfAnswer)) {
          if (String(selfAnswer) === String(name)) {
            result = String(selfAnswer) === String(answer) ? 'right' : 'error'
          } else {
            result = String(name) === String(answer) ? 'right' : ''
          }
        } else {
          result = String(selfAnswer) === String(name) ? 'select' : 'none'
        }
        return result
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      if (this.question) {
        this.initTitle()
      }
    })
  },
  methods: {
    // 调取答题卡
    showAnswerSheet () {
      this.$emit('showAnswerSheet')
    },
    initTitle () {
      if(this.isExercises || this.isCollect == 1 || this.isCollect == 2) return;
      let str = '('
      if (this.parent) {
        str = '(本题'
      }
      this.$refs.stem.innerHTML = this.$refs.stem.innerHTML + str + this.question.score + '分)'
    },
    change (e) {
      // this.isSubmited = false
      console.log(this.selfAnswer)
      let obj = {
        ...this.question,
        num: this.num,
        questionId: this.question.questionId,
        selfAnswer: this.selfAnswer
      }
      if (!obj.selfAnswer) {
        Toast({
          message: '请选择或填写答案'
        })
        return
      }
      // this.isSubmited = true
      this.$emit('change', obj)
    },
    // submit () {
    //   console.log(this.selfAnswer)
    //   let obj = {
    //     ...this.question,
    //     questionId: this.question.id,
    //     selfAnswer: this.selfAnswer
    //   }
    //   if (!obj.selfAnswer) {
    //     Toast({
    //       message: '请选择或填写答案'
    //     })
    //     return
    //   }
    //   this.isSubmited = true
    //   this.$emit('change', obj)
    // },
    // 判断是否在专项练习下做了题
    practiceEvent() {
      let bool = this.isExercises && this.question.selfAnswer ? true : false;
      return bool;
    },
    specialEvent() {
      let bool = this.isExercises && this.question.selfAnswer && !this.question.parentId;
      return bool;
    }
  }
}
</script>

<style lang="scss">
  .question-judge-exam {
    .stem {
      display: block;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #363840;
      line-height: 28px;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 70vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        max-width: 100%;
        vertical-align: middle;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .question {
    width: 100%;
    // height: calc(100vh - 58px - 30px);
    box-sizing: border-box;
    padding: 17px 15px 15px 15px;
    overflow: auto;
    .title {
      color: #333;
      font-size: 16px;
      line-height: 26px;
      .kinds {
        display: inline-block;
        line-height: 17px;
        color: #fff;
        text-align: center;
        background: #1A7EE2;
        border-radius: 8px 8px 0 8px;
        margin-right: 16px;
        padding: 2px 6px;
        font-size: 12px;
      }
      .score {
        color: #999;
      }
    }
    .img {
      width: 80%;
      height: auto;
      display: block;
      margin: 20px auto;
    }
    .content {
      margin-top: 30px;
      margin-bottom: 30px;
      .margin{
        ::v-deep .van-radio__icon.van-radio__icon--round{
          margin-top: 7px;
        }
      }
      .van-radio {
        margin-bottom: 15px;
        color: #333;
        min-height: 38px;
        align-items: flex-start;
        width: 100%;
        margin-right: 0;
        height: 40px;
        border-radius: 10px;
        border: 1px solid #efefef;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        ::v-deep .van-radio__label--disabled {
          color: #323233;
        }
        ::v-deep .van-radio__icon {
          display: none;
          ::v-deep .van-icon {
            color: #fff !important;
            background-color: #E21A1A !important;
            border-color: #E21A1A !important;
          }
        }
      }
      .none {
        border-color: #efefef;
      }
      .select {
        border-color: #1A7EE2;
      }
      .right {
        border-color: #1CB74D;
        ::v-deep .van-radio__icon {
          ::v-deep .van-icon {
            color: #fff !important;
            background-color: #1CB74D !important;
            border-color: #1CB74D !important;
          }
        }
      }
      .error {
        border-color: #E21A1A;
        ::v-deep .van-radio__icon {
          ::v-deep .van-icon {
            color: #fff !important;
            background-color: #E21A1A !important;
            border-color: #E21A1A !important;
          }
        }
      }
      .radio {
        display: inline-block;
        width: 25px;
        height: 25px;
        line-height: 25px;
        margin: 0 10px 0 2px;
        text-align: center;
        border-radius: 50%;
        box-shadow: 0px 2px 4px 0 rgba($color: #000000, $alpha: .2);
        font-size: 15px;
        color: #333;
        &.right {
          background: #1A7EE2;
          color: #fff;
        }
        &.error {
          background: #ff790d;
          color: #fff;
        }
      }
    }
    .infoContent {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #363840;
      .info.selfAnswer {
        span.infoTitle {
        }
        span {
        }
      }
      .info.answer {
        span.infoTitle.underline {
        }
        span {
        }
      }
      .info.analysis {
        span.infoTitle {
        }
        span.quesion-analysis {
          // display: block;
          display: -webkit-inline-box;
          p {
            margin: 0;
            padding: 0;
            // color: #333 !important;
            // font-size: 16px !important;
            // line-height: 26px !important;
          }
          span {
            // color: #333 !important;
            // font-size: 16px !important;
            // line-height: 26px !important;
          }
          p[block-img] {
            text-align: center;
            img {
              width: 60vw !important;
              height: auto;
              border: 5px;
            }
          }
          img {
            height: 40px;
            vertical-align: middle;
          }
        }
      }
      // .analysis {
      //   margin: 30px 15px 70px;
      //   padding: 14px 18px;
      //   font-size: 15px;
      //   color: #333;
      //   background: #eee;
      //   border-radius: 3px;
      //   line-height: 25px;
      //   overflow: hidden;
      //   word-wrap: break-word;
      //   span {
      //     color: #999;
      //   }
      //   .anly {
      //     text-indent: 30px;
      //   }
      // }

    }
    // .analysis {
    //   margin: 30px 15px 70px;
    //   padding: 14px 18px;
    //   font-size: 15px;
    //   color: #333;
    //   background: #eee;
    //   border-radius: 3px;
    //   line-height: 25px;
    //   overflow: hidden;
    //   word-wrap: break-word;
    //   span {
    //     color: #999;
    //   }
    //   .anly {
    //     text-indent: 30px;
    //   }
    // }
  }
</style>
