<template>
  <div :class="`container exam-container`">
    <!-- <div class="subhead">
      <div class="subheadLeft">
        <template v-if="remainTime === '批阅/自改' || remainTime === '预览'">
        </template>
        <template v-else>
          <span>{{ headTitle }}</span>
        </template>
      </div>
      <div
        class="submit-paper-wrap topics"
        style="color: #18a6f3"
        @click="topicShow"
      >
        <span style="font-size: 12px; color: #999"
          >{{ currentIndex + 1 }}/{{ examTotal }}</span
        >
      </div>
    </div> -->
    <!-- 题目 -->
    <template v-if="examList.length != 0">
      <div class="question" v-longpress>
        <questions
          ref="questions"
          :examList="examList"
          :currentIndex="currentIndex"
          :isExercises="!isExam"
          :remainTime="remainTime"
          :select="remainTime!== '批阅/自改' && remainTime != '预览' && remainTime !== '题目收藏' && remainTime !== '我的错题'"
          :isCollect="isCollect"
          @question-result="questionResult"
          @question-index="questionIndex"
          @changeRemarkRead='changeRemarkRead'
          @updateScore='updateScore'
          @showAnswerSheet='topicShow'
        ></questions>
      </div>
    </template>
    <!-- 题目收藏，我的错题无题目数据时，展示占位图 -->
    <template v-if="(remainTime == '题目收藏' || remainTime == '我的错题') && examList.length == 0">
      <div class="noData-area">
        <img src="../../assets/img/noData.png" alt="">
        <span>暂无相关数据</span>
      </div>
    </template>
    <div v-if="currentIndex === this.examList.length - 1" class="lastExam">
      已经到最后一题啦！
    </div>
    <div class="bottom">
      <!-- <div
        @click="submitExam"
        class="submit-paper-wrap topics"
        style="color: #18a6f3"
      >
        <icon-svg
          :iconClass="`${
            checked
              ? 'icon-shensemoshi-jiaojuan023x'
              : 'icon-qiansemoshi-jiaojuan023x'
          }`"
          className="icon-class"
        ></icon-svg>
        <span style="font-size: 12px; color: #999">交卷</span>
      </div> -->
      <!-- <div v-if="!isCollection" v-show="!isOldVersion" @click="collect" class="submit-paper-wrap topics" style="color:#18A6F3">
        <icon-svg :iconClass="`${checked?'icon-shensemoshi-weishoucang3x':'icon-qiansemoshi-weishoucang'}`" className="icon-class"></icon-svg>
        <span style="font-size:12px">收藏</span>
      </div>
      <div v-else v-show="!isOldVersion" @click="cancelCollect" class="submit-paper-wrap topics" style="color:#18A6F3">
        <icon-svg :iconClass="`${checked?'icon-shensemoshi-yishoucang3x':'icon-qiansemoshi-yishoucang'}`" className="icon-class"></icon-svg>
        <span style="font-size:12px;color:#999">收藏</span>
      </div>
      <div @click="openCorrect" class="submit-paper-wrap topics" style="color:#18A6F3">
        <icon-svg :iconClass="`${checked?'icon-shensemoshi-jiucuo3x':'icon-qiansemoshi-jiucuo3x'}`" className="icon-class"></icon-svg>
        <span style="font-size:12px;color:#999">纠错</span>
      </div> -->
      <!-- <div class="submit-paper-wrap" style="color:#18A6F3">
        <icon-svg :iconClass="`${checked?'icon-shensemoshi-beiti3x':'icon-qiansemoshi-beiti3x'}`" className="icon-class"></icon-svg>
        <span style="font-size:12px;color:#999">背题模式</span>
      </div> -->
      <!-- <div
        class="submit-paper-wrap topics"
        style="color: #18a6f3"
        @click="topicShow"
      >
        <icon-svg
          :iconClass="`${
            checked ? 'icon-shensemoshi-tishu3x' : 'icon-qiansemoshi-tishu3x'
          }`"
          className="icon-class-small"
        ></icon-svg>
        <span style="font-size: 12px; color: #999"
          >{{ currentIndex + 1 }}/{{ examTotal }}</span
        >
      </div> -->
      <div class="footBtnLeft" v-if="this.questionList.length > 0">
        <template v-if="remainTime != '我的错题'">
          <div @click="handcollect">
            <img :src="isCollection ? iconList[0]:iconList[1]" alt="" srcset="" />
            <span>收藏</span>
          </div>
        </template>
        <template v-else>
          <div @click="handDelete">
            <img class="deleteIcon" src="../../assets/img/helpStudy/delErrorQuestion.png" alt="" srcset="" />
            <span>移除错题</span>
          </div>
        </template>
      </div>
      <div class="answerSheetBtn" @click="topicShow">
        <img src="@/assets/img/answerSheet.png" alt="" srcset="" />
        <span>答题卡</span>
      </div>
      <!-- 考试倒计时 -->
      <!-- v-show="showCountdown" -->
      <div class="footBtnRight" v-if="remainTime != '批阅/自改' && remainTime != '预览' && remainTime != '专项练习' && remainTime != '题目收藏' && remainTime != '我的错题'">
        <template v-if="remainTime.length>0 && remainTime !== '批阅/自改'">
          剩余时间：
          <van-count-down
            ref="count-down"
            @finish="submitSeft"
            :time="timeLeft"
          >
          </van-count-down>
        </template>
      </div>
    </div>
    <!-- 答题卡 -->
    <answer-sheet
      v-if="showAnswerSheet"
      :answerCardList="answerCardList"
      :sumed="sumed"
      :isExercises="!isExam"
      :isCollect="isCollect"
      :isActive="isActive"
      :remainTime="remainTime"
      :sheetBtnName="sheetBtnName"
      :paperType="paperType"
      @submit="submit"
      @close="topicHide"
      @to-current-exam="toCurrentExam"
      @changeQuestionType="changeQuestionType"
      @nextEvent='nextEvent'
    ></answer-sheet>
  </div>
</template>

<script>
import { Toast, Dialog, Notify } from 'vant'
import Questions from './examQuestions/questions'
import { getQueryString } from '@/assets/js/util.js'
import AnswerSheet from './examAnswerSheet'
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'
import yxsSlider from '@/components/yxsSlider'
function addAttr (richText) {
  try {
    if (!richText) return ''
    richText = String(richText)
    if (!richText.trim()) return ''
    const divNode = document.createElement('div')
    divNode.innerHTML = richText
    const pNodeList = divNode.getElementsByTagName('p');
    // debugger

    [].forEach.call(pNodeList, (pItem, index) => {
      if (pItem.children.length > 0) {
        // 去除<br>标签
        [].forEach.call(pItem.children, (child) => {
          if (child.tagName && child.tagName.toLocaleLowerCase() === 'br') {
            pItem.removeChild(child)
          }
        })
      }

      if (
        pItem.childNodes.length === 1 &&
        pItem.firstChild.tagName &&
        pItem.firstChild.tagName.toLocaleLowerCase() === 'img'
      ) {
        pItem.setAttribute('block-img', 'true')
      }
    })
    return divNode.innerHTML
  } catch (e) {
    console.error(e)
    return richText
  }
}
function addPrefix (richText, prefix) {
  try {
    if (!richText) return ''
    richText = String(richText)
    if (!richText.trim()) return richText
    const divNode = document.createElement('div')
    divNode.innerHTML = richText
    const pNodeList = divNode.getElementsByTagName('p')
    const spanNode = document.createTextNode(prefix + '')
    if (pNodeList.length > 0) {
      // 富文本
      const pNode = pNodeList[0]
      if (pNode.firstChild) {
        pNode.insertBefore(spanNode, pNode.firstChild)
      } else {
        pNode.appendChild(spanNode)
      }
    } else {
      // 老题，非富文本
      divNode.insertBefore(spanNode, divNode.firstChild)
    }
    return divNode.innerHTML
  } catch (e) {
    return richText
  }
}
export default {
  components: {
    Questions,
    AnswerSheet,
    yxsSlider
  },
  props: {
    questionList: {
      // 题目列表
      type: Array,
      default: function () {
        return []
      }
    },
    headTitle: {
      // 左上标题自定义
      type: String,
      default: function () {
        return ''
      }
    },
    sheetBtnName: {
      // 答题卡按钮自定义
      type: Array,
      default: function () {
        return []
      }
    },
    remainTime: {
      // 剩余时间 (s) remainTime='批阅/自改'(批阅状态或者练习自改状态)
      type: String,
      default: function () {
        return ''
      }
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  watch: {
    questionList: {
      deep: true,
      handler (newV, oldV) {
        console.log(newV,852369)
        console.log(this.answerCardList,854444)
        if(this.isCollect >= 1) {
          this.initPaper(newV)
        }
      }
    }
  },
  data () {
    return {
      time: 0, // 考试剩余时间
      isExam: false, // ture 考试,false 练习题
      paperType: true, // ture 做题,false 查看解析
      paperSubName: '本地2测试', // 作业名称
      courseId: 0, // 考试id
      workId: 0, // 作业id
      studentExamId: 0, // 考试id
      richText:
        "<p>无</p><p><img src='https://file.zhihuischool.cn/f38e0707035243fbb28a5687a289cfaf.png' title='' alt=''/></p>",
      isWiating: false,
      showCountdown: false,
      show: false,
      isCollection: false,
      sumList: [],
      examList: [],
      answerCardList: [],
      examTotal: 0,
      currentExam: {},
      currentIndex: 0,
      showAnswerSheet: false,
      current: 1,
      size: 10,
      timeLeft: 6000 * 1000,
      isClickToLast: false, // 是否点击题目到的最后一题
      lessonId: '', // 课时id
      chapterId: '',
      // courseId: '',
      resultId: '',
      needMarking: '',
      isOldVersion: false,
      correctShow: false, // 纠错弹窗
      radio: '0',
      message: '',
      fontSizeSheetShow: false,
      multipleList: [
        {
          multiple: 0.8,
          name: '小'
        },
        {
          multiple: 1,
          name: '标准',
          default: true
        },
        {
          multiple: 1.5,
          name: '大'
        },
        {
          multiple: 2,
          name: '超大'
        }
      ],
      checked: false,
      iconList: [
        require('@/assets/img/helpStudy/collected.png'),
        require('@/assets/img/helpStudy/collectNo.png')
      ],
      isActive: 1, // 题目类型
    }
  },
  beforeDestroy () {},
  computed: {
    sumed () {
      // let uniqueArr = this.answerCardList.filter(item => (item.rightFlag === 1 || item.rightFlag === '1' || item.rightFlag === '0' || item.rightFlag === 0))
      // return uniqueArr.length
      return this.currentIndex + 1
    }
  },
  mounted () {
    // document.querySelector('#app').style.overflow = 'hidden'
  },
  created () {
    console.log(sessionStorage.getItem('token'))
    this.paperSubName = '本地测试'
    this.workid = 14095
    this.courseId = 3144
    this.isExam = false
    this.paperType = true
    // false 解析
    // this.getPaper(this.workid, 1);
    if (this.questionList.length > 0) {
      this.initPaper(this.questionList)
      this.timeLeft = parseInt(this.remainTime) * 1000
    }
  },
  methods: {
    // *****************************智汇-新增开始
    // 批阅改分数
    updateScore (val) {
      this.$emit('updateScore', val)
    },
    // 批阅回调
    reviewCallback (reviewVal) {
      console.log(reviewVal,'reviewVal');
      if (reviewVal.typeId == 7 || reviewVal.typeId == 8) {
        this.examList[reviewVal.num - 1].childrenList[reviewVal.miniNum].selfScore = Number(reviewVal.selfScore)
        this.$set(this.answerCardList[reviewVal.num - 1].childrenList[reviewVal.miniNum], 'selfScore', Number(reviewVal.selfScore))
      } else {
        this.examList[reviewVal.num - 1].selfScore = reviewVal.selfScore
        this.$set(this.answerCardList[reviewVal.num - 1], 'selfAnswer', reviewVal.selfScore)
      }
    },
    // 批阅改对错
    changeRemarkRead (val) {
      console.log(val,'paperVal');
      this.$emit('changeRemarkRead', val)
    },
    remarkReadCallback (val) {
      console.log(val,'remarkReadCallback');
      // return
      if (val.parentTypeId == 7 || val.parentTypeId == 8) {
        this.examList[val.num - 1].childrenList[val.miniNum].trueFlag = Number(val.trueFlag)
        this.$set(this.answerCardList[val.num - 1].childrenList[val.miniNum], 'trueFlag', Number(val.trueFlag))
        console.log(1, this.answerCardList[val.num-1]);
        if(this.isExam) {
          let num = 0
          for (const i in this.answerCardList[val.num - 1].childrenList) {
            if (this.answerCardList[val.num - 1].childrenList[i].trueFlag == -1) {
              num++
            }
          }
          if (num == 0) {
            this.examList[val.num - 1].trueFlag = Number(val.trueFlag)
            this.$set(this.answerCardList[val.num - 1], 'trueFlag', Number(val.trueFlag))
          }
        } else {
          // 专项练习下，材料，配伍题下的子题是否全部正确
          let isAllDo = 0; // 存在未答题的数量
          let isTrueDo = 0; // 子题答题正确的数量
          let flagNum; // 该答题是否正确
          for (const i in this.answerCardList[val.num - 1].childrenList) {
            if (this.answerCardList[val.num - 1].childrenList[i].trueFlag == 1) isTrueDo++
            if(this.answerCardList[val.num - 1].childrenList[i].trueFlag === null) isAllDo++
          }
          if(isAllDo == 0) {
            flagNum = isTrueDo == this.answerCardList[val.num - 1].childrenList.length ? 1 : 0
            this.examList[val.num - 1].trueFlag = Number(flagNum)
            this.$set(this.answerCardList[val.num - 1], 'trueFlag', Number(flagNum))
          }
        }
      } else {
        this.examList[val.num - 1].trueFlag = Number(val.trueFlag)
        this.$set(this.answerCardList[val.num - 1], 'trueFlag', Number(val.trueFlag))
        console.log(2, this.answerCardList[val.num-1]);
      }
      if(val.parentId) return;
      this.$nextTick((_) => {
        this.$refs.questions.$refs.swiper.next()
      })
    },
    // 提交做题
    submit (val) {
      if (this.remainTime == '批阅/自改') {
        let num = 0
        for (const i in this.examList) {
          if (this.examList[i].trueFlag === -1 || this.examList[i].trueFlag === null) {
            Toast('您还有题没批阅完！')
            num++
            break
          }
        }
        if (num==0) {
          this.$emit('submit', val)
        }
      } else {
        this.$emit('submit', val)
      }
    },
    // 开始考试
    beginExam () {
      const data = {
        studentExamId: parseInt(this.workid)
      }
      const data2 = {
        studentExamId: this.workid
        // type: type
      }
      this.$request
        .putExamBegin(data)
        .then((res) => {
          if (res.data.code === 0) {
            const remainTime = res.data.data.remainTime
            this.timeLeft = remainTime * 1000
            // this.getPaper(this.workid, 1)
            this.$request
              .getExamPaper(data2)
              .then((res) => {
                this.initPaper(res)
              })
              .finally(() => {
                Toast.clear()
              })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 倒计时主动交卷-start
    submitSeft () {
      this.submit('submitSeft')
    },
    submitCallback () {
      Dialog.alert({
        title: '温馨提示',
        message: '考试结束，已自动提交试卷'
      })
    },
    // 倒计时主动交卷-end

    // *****************************智汇-新增结束
    wyChange () {
      // console.log(11111);
      // window.addEventListener('resize', () => {
      // if (document.activeElement.tagName == 'INPUT') {
      //   window.setTimeout(() => {
      //     console.log(555)
      //     document.activeElement.scrollIntoViewIfNeeded()
      //     console.log(666)
      //   }, 500)
      // }
      // })
      // document.querySelector('.wybc').scrollIntoView()
    },
    // 题目初始化 *
    initPaper (questionList) {
      this.isExam = this.remainTime == '专项练习' ? false : true
      console.log(this.examList, 'examlist0', questionList)
      questionList = questionList.map((question, childrenIndex) => {
        if (
          question.typeId === 1 ||
          question.typeId === 2 ||
          question.typeId === 3
        ) {
          // 单选，多选，判断
          return {
            ...question,
            optionContents: JSON.stringify(JSON.parse(question.optionsContent).map((optionItem) => ({
              ...optionItem,
              content: addAttr(optionItem.content)
            }))
            ),
            // stem: addAttr(
            //   addPrefix(question.stem, childrenIndex + 1 + ".")
            // ),
            analysis: addAttr(question.analysis)
          }
        } else {
          // 除选择其他题型
          return {
            ...question,
            // stem: addAttr(
            //   addPrefix(question.stem, childrenIndex + 1 + ".")
            // ),
            analysis: addAttr(question.analysis)
          }
        }
      })
      // console.log(questionList, 'res1');
      // 大题数据处理
      questionList = questionList.map((question) => {
        if (
          (question.typeId === 7 || question.typeId === 8) &&
            question.childrenList &&
            question.childrenList.length > 0
        ) {
          return {
            ...question,
            selfAnswer: question.childrenList.some(
              (item) => item.selfAnswer
            ),
            childrenList: question.childrenList.map(
              (childrenItem, childrenIndex) => {
                if (
                  childrenItem.typeId === 1 ||
                  childrenItem.typeId === 2 ||
                  childrenItem.typeId === 3
                ) {
                  // 单选，多选，判断
                  return {
                    ...childrenItem,
                    optionContents: JSON.stringify(
                      JSON.parse(childrenItem.optionsContent).map(
                        (optionItem) => ({
                          ...optionItem,
                          content: addAttr(optionItem.content)
                        })
                      )
                    ),
                    // stem: addAttr(
                    //   addPrefix(
                    //     childrenItem.stem,
                    //     "（" + (childrenIndex + 1) + "）"
                    //   )
                    // ),
                    analysis: addAttr(childrenItem.analysis)
                  }
                } else {
                  return {
                    ...childrenItem,
                    // stem: addAttr(
                    //   addPrefix(
                    //     childrenItem.stem,
                    //     "（" + (childrenIndex + 1) + "）"
                    //   )
                    // ),
                    analysis: addAttr(childrenItem.analysis)
                  }
                }
              }
            )
          }
        }
        return {
          ...question,
          childrenList: []
        }
      })

      // console.log(questionList, "<><<<<");

      if (_.isArray(questionList) && questionList.length > 0) {
        // TODO: 处理试卷没有考题的情况
      }

      if (questionList.every((item) => item.answer)) {
        // 题目全部答完了
        // TODO: 处理试题全部做完了的情况
      }

      // const lastQuestionIndex = _.findIndex(
      //   questionList,
      //   (item) => item.lastFlag === 1 || item.lastFlag === '1'
      // )

      // this.$refs.questions.$refs.swiper.swipeTo(lastQuestionIndex)
      // console.log('cardList', _.cloneDeep(res.data))
      this.answerCardList = questionList
      this.examList = questionList
      // console.log(this.examList, 'examlist0')
      this.examTotal = questionList.length
      // this.resultId = res.data.examResult.id
      // this.needMarking = res.data.needMarking
      this.init()
      // if (type === 2) {
      //   this.toCurrentExam(1)
      // }
    },
    /**
     * 倒计时交卷
     */
    onExamFinished () {
      console.log('提交试卷')
      this.$api.exam
        .submitExam({
          examPaperId: this.examId,
          examResultId: this.resultId,
          needMarking: this.needMarking
        })
        .then((res) => {
          // TODO: 接口返回错误处理
          Dialog.alert({
            title: '温馨提示',
            message: '考试结束，已自动提交试卷'
          }).then(() => {
            //  window.location.href = 'sendmessage/setcolormodeexam?mode=day'
            // this.$router.replace({ path: '/exam/examResult', query: { examResultId: this.resultId, userToken: this.userToken, tedantId: this.tenantId } })
            window.location.replace(
              `/exam/examResult?examResultId=${this.resultId}&userToken=${this.userToken}&tedantId=${this.tenantId}`
            )
          })
        })
    },
    /**
     * 主动交卷
     */
    submitExam () {
      Dialog.confirm({
        title: '温馨提示',
        message: '确定提交试卷吗？',
        className: `${
          this.userSetting.colorModeExam === 'day' ? 'day' : 'night'
        }`
      })
        .then(() => {
          console.log('confirm')
          console.log('examId')
          console.log(this.examId)
          console.log('examId')
          console.log('examResultId')
          console.log(this.examResultId)
          console.log('examResultId')
          console.log('needMarking')
          console.log(this.needMarking)
          console.log('needMarking')
          console.log('提交试卷')
          this.$api.exam
            .submitExam({
              examPaperId: this.examId,
              examResultId: this.resultId,
              needMarking: this.needMarking
            })
            .then((res) => {
              // TODO: 接口异常处理
              Dialog.alert({
                title: '温馨提示',
                message: '已提交试卷'
              }).then(() => {
                this.$router.replace({
                  path: '/exam/examResult',
                  query: {
                    examResultId: this.resultId,
                    userToken: this.userToken,
                    tedantId: this.tenantId
                  }
                })
              })
            })
        })
        .catch(() => {
          // console.log("cancel");
        })
    },
    xx () {},
    clickKnow () {
      this.show = true
      // 引导页载入缓存
      window.localStorage.setItem('tipShow', true)
    },
    // 选择、作答回调
    questionResult (val) {
      const valQ = val
      this.$emit('saveAnswer', val)
      // 练习题保存答案
    },
    // 做题保存回调 type: 1.真题精编/模拟考试 2.专项练习
    saveCallback (valQ, val, type = 1) {
      if (val.typeId === 7 || val.typeId === 8) {
        valQ = val.childrenList[val.miniNum]
      }
      console.log(valQ, 'valQ')
      console.log(val, 'valQ2')
      const list = this.examList
      this.answerCardList = list
      if (val.typeId === 7 || val.typeId === 8) {
        // console.log(this.examList[this.currentIndex].childrenList[val.miniNum].selfAnswer, 'val.selfAnswer11')
        console.log(valQ, 'val.selfAnswer1', val.num)
        // this.examList[val.num - 1].childrenList[val.miniNum].selfAnswer = valQ.selfAnswer
        this.$set(this.examList[val.num - 1], 'childrenList', JSON.parse(JSON.stringify(val.childrenList)))
        this.$set(
          this.answerCardList[val.num - 1].childrenList[val.miniNum],
          'selfAnswer',
          valQ.selfAnswer
        )
        // this.$forceUpdate()
      } else {
        console.log(val.selfAnswer, 'val.selfAnswer2', val.num)
        // this.examList[val.num - 1].selfAnswer = val.selfAnswer
        this.$set(this.examList[val.num - 1], 'childrenList', JSON.parse(JSON.stringify(val.childrenList)))
        this.$set(
          this.answerCardList[val.num - 1],
          'selfAnswer',
          val.selfAnswer
        )
      }
      console.log(this.examList)
      console.log(this.answerCardList)
      // this.$refs.questions.getQuestion(this.examList)
      // 最后一题
      if (this.currentIndex === this.examList.length - 1) {
        if (
          val.parentQuestion &&
            (val.parentQuestion.typeId === 7 || val.parentQuestion.typeId === 8)
        ) {
          let flag = false
          if (
            val.parentQuestion &&
              val.parentQuestion.childrenList.length > 0
          ) {
            const num = 0
            val.childrenList.forEach((item) => {
              if (item.selfAnswer !== '' || item.selfAnswer !== null) {
                // ++num
                flag = true
                // if (num === val.childrenList.length) {
                // }
              }
              // console.log(flag, 'flag', num)
            })
          }
          if (!flag) {
            Toast({
              message: '已经到最后一题啦！',
              duration: 1000
            })
          }
        } else {
          Toast({
            message: '已经到最后一题啦！',
            duration: 1000
          })
        }
      }
      // 当type==2，处于专项练习，判断选择答案和争取答案是否一致
      if(type == 2) {
        if(val.typeId < 5) {
          this.$set(
            this.answerCardList[val.num - 1],
            'trueFlag',
            val.trueFlag
          )
          if(val.trueFlag != 1) {
            return;
          }
        } else if(val.typeId == 7 || val.typeId == 8) {
          let trueNum = 0;
          let nums = 0;
          val.childrenList.forEach(item => {
            if(item.trueFlag === null) nums++
            if(item.trueFlag == 1) trueNum++
          })
          console.log(nums, trueNum, '有有有有有有')
          if(nums == 0) {
            console.log('去你妈的·1')
            this.$set(
              this.examList[val.num - 1],
              'trueFlag',
              trueNum == this.examList[val.num - 1].childrenList.length ? 1 : 0
            )
            this.$set(
              this.answerCardList[val.num - 1],
              'trueFlag',
               trueNum == this.answerCardList[val.num - 1].childrenList.length ? 1 : 0
            )
          }
        }
      }
      if (val.typeId === 1 || val.typeId === 4) {
        if (val.parentQuestion) {
          // console.log('有小题')
        } else {
          // console.log('无小题')
          this.$nextTick((_) => {
            this.$refs.questions.$refs.swiper.next()
          })
        }
      }
    },
    questionIndex (val) {
      this.$store.state.questionIndex = val
      this.currentIndex = val
      this.init()
    },
    init () {
      this.currentExam = this.examList[this.currentIndex]
      // console.log(this.currentExam, "9999");
      this.isCollection = this.questionList.length > 0 ? this.currentExam.collect : false
    },
    // // 纠错
    // openCorrect () {
    //   this.message = ''
    //   this.radio = '0'
    //   this.correctShow = true
    // },
    // correctSumbit () {
    //   this.$api.exam.correct({
    //     questionId: this.examList[this.currentIndex].id,
    //     questionType: this.examList[this.currentIndex].questionType,
    //     type: this.radio,
    //     title: this.message,
    //     lessonId: this.lessonId,
    //     chapterId: this.chapterId,
    //     courseId: this.courseId
    //   }).then(res => {
    //     console.log(res, '纠错')
    //     if (res.code === 0 && res.data) {
    //       Toast({
    //         message: '纠错成功',
    //         duration: 1000
    //       })
    //       this.correctShow = false
    //     }
    //   })
    // },
    // 收藏
    handcollect () {
      let examInfo = this.examList[this.currentIndex]
      let obj = {
        action: this.isCollection ? 0 : 1,
        courseId: examInfo.courseId,
        questionId: examInfo.questionId,
        questionType: examInfo.typeId,
        source: examInfo.source
      }
      this.$emit('handcollect', obj)
    },
    failCollect(type) {
      if(type == 'success') {
        this.examList[this.currentIndex].collect = !this.examList[this.currentIndex].collect
        this.isCollection = !this.isCollection
      }
    },
    collect () {
      this.$api.exam.examCollect({
        examPaperId: this.examId,
        questionFrom: this.questionFrom,
        questionId: this.examList[this.currentIndex].id,
        typeId: this.examList[this.currentIndex].typeId
      })
        .then(res => {
          console.log('收藏', res)
          if (res.code === 0 && res.data) {
            Toast({
              message: '收藏成功',
              duration: 1000
            })
            this.examList[this.currentIndex].collectionId = res.data.collectionId
            this.examList[this.currentIndex].collectionStatus = 1
            this.init()
          } else {
            Toast({
              message: res.msg,
              duration: 1000
            })
          }
        })
    },
    // 取消收藏
    cancelCollect () {
      this.$api.exam.examUncollect({
        examPaperId: this.examId,
        questionFrom: this.questionFrom,
        questionId: this.examList[this.currentIndex].id
      })
        .then(res => {
          if (res.code === 0 && res.data) {
            Toast({
              message: '取消收藏成功',
              duration: 1000
            })
            this.isCollection = 0
            this.examList[this.currentIndex].collectionStatus = 0
            this.init()
          } else {
            Toast({
              message: res.msg,
              duration: 1000
            })
          }
        })
    },
    // 上一题
    prev () {
      if (this.currentIndex === 0) {
        Toast({
          message: '已经到第一道题啦!',
          duration: 1000
        })
        return
      }
      this.$nextTick((_) => {
        this.$refs.questions.$refs.swiper.prev()
      })
    },
    // 下一题
    next () {
      if (this.currentIndex === this.examList.length - 1) {
        Toast({
          message: '已经到最后一题啦！',
          duration: 1000
        })
      }
      this.$nextTick((_) => {
        this.$refs.questions.$refs.swiper.next()
      })
    },
    topicShow () {
      this.showAnswerSheet = true
    },
    topicHide () {
      this.showAnswerSheet = false
    },
    // 跳转到指定题目
    toCurrentExam (index) {
      this.isClickToLast = true
      this.$nextTick((_) => {
        this.$refs.questions.$refs.swiper.swipeTo(index)
        this.topicHide()
      })
    },
    changeQuestionType(val) {
      this.isActive = val
      this.$emit('changeQuestionType', val)
    },
    // 移除错题
    handDelete() {
      let examInfo = this.examList[this.currentIndex]
      let obj = {
        action: 0,
        courseId: examInfo.courseId,
        questionId: examInfo.questionId,
        questionType: examInfo.typeId,
        source: examInfo.source,
        type: this.isCollect
      }
      this.$request.postHelpStudyQuestionCollect(obj).then(res => {
        if(res.data.code == 0) {
          // this.examList.splice(this.currentIndex, 1)
          // this.answerCardList.splice(this.currentIndex, 1)
          // this.questionList.splice(this.currentIndex, 1)
          this.$emit('getNewData')
          Notify({ type: 'success', message: '移除成功' })
        } else {
          Notify({ type: 'success', message: '移除失败' })
        }
      })
    },
    nextEvent(type) {
      this.$emit('nextEvent', type)
    }
  }
}
</script>
<style lang="scss">
.van-dialog.night {
  background: #404040;
  .van-dialog__header {
    color: #ddd;
  }
  .van-dialog__message {
    color: #ddd;
  }
  .van-button {
    background: #404040;
    color: #ddd;
    &.van-dialog__confirm {
      color: #1989fa;
    }
  }
}
.van-cell:not(:last-child)::after {
  border-bottom: none !important;
}
.exam-container {
  .van-swipe-item {
    background: #fff;
  }
  &.night {
    .van-swipe-item {
      background: #000;
    }
  }
  //   .fontSheet.van-action-sheet{
  //       height: 173px;
  //   }
}
</style>
<style lang="scss" scoped>
.icon-class-small {
  width: 18px !important;
  height: 18px !important;
}
.topics {
  font-size: 12px;
  color: #333;
  span {
    color: #999;
  }
  svg {
    display: block;
    width: 24px;
    height: 24px;
    margin: 0 auto 5px;
  }
}
.exam-container {
  ::v-deep .content-action-sheet {
    // height: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    box-sizing: border-box;
    .nightBox {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      .nightMode {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(61, 61, 61, 1);
      }
    }
    .wzsf {
      height: 18px;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(61, 61, 61, 1);
      line-height: 18px;
      padding: 15px 0;
    }
  }
}
.exam-countdown {
  padding: 5px;
  text-align: center;
}
.container {
  width: 100%;
  height: 100vh;
  background: #fff;
  padding: 0;
}
.shadow {
  width: 100%;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.4);
  z-index: 11;
  .tip {
    padding: 255px 15px 0;
    overflow: hidden;
    color: #eee;
    .left {
      float: left;
      img {
        display: block;
        width: 42px;
        height: 60px;
        padding-top: 22px;
        margin: 0 auto;
      }
    }
    .right {
      float: right;
      img {
        display: block;
        width: 38px;
        height: 60px;
        padding-top: 22px;
        margin: 0 auto;
      }
    }
  }
  .know {
    width: 127px;
    line-height: 40px;
    margin: 83px auto 0;
    background: #fff;
    color: #18a6f3;
    text-align: center;
    border: 1px solid #18a6f3;
    border-radius: 20px;
  }
}
.subhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0.4rem 0;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #363840;
}
.question {
  padding-bottom:100px;
  overflow: visible;
}
.lastExam {
  margin-top: 50px;
  width: 100%;
  font-size: 15px;
  color: #c6c6c6;
  text-align: center;
}
.bt-collect {
  text-align: center;
}
.bottom {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  // width: 348px;
  height: 32px;
  padding: 20px 0;
  box-shadow: 0 2px 4px 0 rgba($color: #000000, $alpha: 0.2);
  background: #fff;
  align-items: center;
  .collect {
    width: 48px;
    font-size: 12px;
    color: #333;
    text-align: center;
    img {
      display: block;
      width: 14px;
      height: 13px;
      margin: 0 auto 5px;
    }
  }
  .footBtnLeft {
    div {
      display: flex;
      align-items: center;
      img {
        height: 38px;
      }
      .deleteIcon {
        // height: 28px;
        margin-right: 12px;
      }
    }
  }
  .answerSheetBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 12px;
    }
  }
  .footBtnRight {
    color: #363840
  }
  .submit {
    font-size: 12px;
    color: #18a6f3;
    img {
      display: block;
      width: 14px;
      height: 14px;
      margin: 0 auto 5px;
    }
  }
  .submit-paper-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .submit-paper {
    font-size: 13px;
  }
  .topics {
    font-size: 0.3rem;
    color: #363840;
  }
}
</style>
<style lang="scss">
.exam-container {
  .van-count-down {
    color: #e21a1a;
    display: inline-block;
    // height: 22px;
    // box-sizing: border-box;
    // color: #aaa;
    // font-size: 12px;
    // border-top-right-radius: 10px;
    // border-bottom-right-radius: 10px;
    // display: inline-block;
    // padding: 0 10px;
    // margin-top: 8px;
  }
}
.van-dialog__header {
  padding-top: 8px;
}
.btn {
  display: flex;
  justify-content: space-around;
  position: fixed;
  bottom: 58px;
  width: 100%;
  z-index: 3;
  background-color: #fff;
  padding: 3px 0;
  .prev {
    // width: 80px;
    // line-height: 30px;
    // font-size: 13px;
    // color: #18A6F3;
    // text-align: center;
    // border: 1px solid #18A6F3;
    // border-radius: 17px;
    // box-sizing: border-box;
    width: 108px;
    height: 34px;
    border-radius: 6px;
    border: 1px solid rgba(23, 125, 219, 1);
    text-align: center;
    font-size: 13px;
    box-sizing: border-box;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(23, 125, 219, 1);
    line-height: 34px;
    margin-right: -80px;
  }
  .next {
    // width: 80px;
    // line-height: 30px;
    // font-size: 13px;
    // color: #fff;
    // text-align: center;
    // background: #18A6F3;
    // border-radius: 17px;
    width: 108px;
    height: 34px;
    background: rgba(23, 125, 219, 1);
    border-radius: 6px;
    text-align: center;
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(247, 248, 249, 1);
    line-height: 34px;
  }
}
.correctType {
  height: 20px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(138, 138, 142, 1);
  line-height: 20px;
  margin-top: 20px;
  padding-left: 20px;
  margin-bottom: 15px;
}
.content .van-radio__label {
  word-break: break-all;
}
.correctPop {
  &.night {
    background: #404040;
    .van-radio__label {
      color: #ddd;
    }
    .van-field__control {
      background: #ccc;
    }
  }
  &.day {
    background: #fff;
  }
  .van-radio:nth-child(1),
  .van-radio:nth-child(3) {
    width: 48%;
    height: 40px;
    margin-left: 20px;
    line-height: 40px;
  }
  .van-radio:nth-child(2),
  .van-radio:nth-child(4) {
    height: 40px;
    line-height: 40px;
  }
  .van-field__control {
    background: rgba(247, 247, 247, 1);
    border-radius: 16px;
    text-indent: 20px;
  }
  .submit {
    width: 170px;
    height: 36px;
    background: rgba(24, 166, 243, 1);
    border-radius: 4px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    line-height: 36px;
    margin-left: calc(50% - 85px);
    text-align: center;
    margin-top: 5px;
  }
}
.noData-area {
  width: 100%;
  height: 100%;
  // background-color: blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > img {
    width: 200px;
  }
  & > span {
    font-size: 14px;
  }
}
</style>
