<template>
  <div class="answerArea">
    <!-- 非客观题 -->
    <template v-if="questionNew.typeId <= 4">
      <div class="answerBox">
        <!-- 答案字符 -->
        <template v-if="questionNew.typeId==1">
          <div class="analysis">正确答案：<span v-html="questionNew.answer || '暂无'"></span></div>
          <div class="selfAnswer" v-if='hiddenToMyAnswer()'>我的答案：<span style="display: inline;">{{ questionNew.selfAnswer || '未答题' }}</span></div>
        </template>
        <!-- 答案布尔值 -->
        <template v-if="questionNew.typeId==4">
          <div class="analysis">正确答案：{{ questionNew.answer=='true' ? '正确':'错误' }}</div>
          <div class="selfAnswer" v-if='hiddenToMyAnswer()'>我的答案：<span style="display: inline;">{{ questionNew.selfAnswer ? (questionNew.selfAnswer=='true'?'正确':'错误') : '未答题' }}</span></div>
        </template>
        <!-- 答案数组形式 -->
        <template v-if="questionNew.typeId==2|| questionNew.typeId==3">
          <div class="analysis">正确答案：{{ arrToStr(questionNew.answer) || "无正确答案" }}</div>
          <div class="selfAnswer" v-if='hiddenToMyAnswer()'>我的答案：<span style="display: inline;">{{ arrToStr(questionNew.selfAnswer) || "未答题" }}</span></div>
        </template>
      </div>
      <div class="anly">
        <template v-if="questionNew.analysis">
          解析：<span v-html="questionNew.analysis"></span>
        </template>
        <template v-else>
          解析：<span>暂无</span>
        </template>
      </div>
      <div class="updateScore" v-if="!isExercises && isCollect < 1">
        <van-field
          v-model="areaScore"
          type="number"
          label="得分："
          :disabled='true'
          class="bg_gary"
        />
      </div>
    </template>
    <!-- 客观题 -->
    <template v-if="questionNew.typeId== 5 || questionNew.typeId== 6">
      <div class="analysis" v-if="questionNew.typeId== 5">答案：{{ arrToStr(questionNew.answer) || "无答案" }}</span></div>
      <div class="analysis" v-else>答案：<span v-html="questionNew.answer || '无答案'"></span></div>
      <div class="anly">
        <template v-if="questionNew.analysis">
          解析：<span v-html="questionNew.analysis"></span>
        </template>
        <template v-else>
          解析：<span>暂无</span>
        </template>
      </div>
      <div class="updateScore" v-if="!isExercises && isCollect < 1">
        <van-field
          v-model="areaScore"
          type="number"
          label="得分："
          @blur='updateScore'
          :class="{bg_gary:remainTime=='预览'}"
          :disabled="remainTime=='预览'"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'answerArea',
  props: {
    selfScore: {
      type: Number,
      default: function () {
        return null
      }
    },
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    remainTime: {
      // 剩余时间 (s) remainTime='批阅/自改'(批阅状态或者练习自改状态)
      type: String,
      default: function () {
        return ''
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  watch: {
    question (newV, oldV) {
      console.log('watchQ', newV);
      this.questionNew = newV
    },
    selfScore (newV, oldV) {
      console.log('watchQ', newV);
      this.questionNew = newV
    }
  },
  data () {
    return {
      checked: '',
      areaScore: null,
      questionNew: {}
    }
  },
  computed: {
    arrToStr () {
      return function (answer) {
        if (answer) {
          return JSON.parse(answer || '').toString()
        } else {
          return false
        }
      }
    }
  },
  created () {
    if (this.question) {
      this.questionNew = this.question
      this.areaScore = this.question.selfScore
    }
  },
  methods: {
    changeSelect () {
      console.log(this.checked)
      this.$emit('changeSelect', this.checked)
    },
    updateScoreChild (val) {
      this.areaScore = Number(val)
    },
    // 打分/更新分数
    updateScore (val) {
      console.log(this.areaScore, 'areaScore', this.questionNew)
      if (Number(this.areaScore) <= Number(this.questionNew.score)) {
        console.log(5);
        if (Number(this.areaScore) === 0 || Number(this.areaScore) !== Number(this.questionNew.selfScore)) {
          let obj = {
            paperId: 15958,
            questionId: this.questionNew.questionId,
            selfScore: this.areaScore,
            typeId: this.questionNew.typeId,
          }
          console.log(this.areaScore,'areascore')
          // return
          if (this.areaScore) {
            console.log(87);
            this.$emit('updateScore', obj)
          }
        }
      } else {
        this.areaScore = null
        Toast('您打的分数超过本题分值！')
      }

    },
    hiddenToMyAnswer() {
      let bool = this.isCollect == 2 && this.questionNew.parentId && !this.questionNew.selfAnswer
      return !bool
    }
  }
}
</script>

<style lang="scss" scoped>
.answerArea{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #363840;
  line-height: 28px;
  div {
    margin-bottom: 12px;
    line-height: 28px;
  }
  .answerBox {
    display: flex;
    justify-content: space-between;
    div {
      width: 430px;
      margin-bottom: 0;
      line-height: 28px;
    }
  }
  .anly{
    word-break: break-all;
  }
  .updateScore {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 16px;
    .bg_gary {
      ::v-deep .van-cell__value{
        .van-field__body {
          .van-field__control {
            background: #EFEFEF;
          }
        }
      }
    }
    .van-cell {
      padding: 0;
      ::v-deep .van-cell__title{
        width: auto;
        height: 28px;
        font-size: 16px;
        margin-right: 0px;
        color: #363840;
        line-height: 28px;
      }
      ::v-deep .van-cell__value{
        .van-field__body {
          .van-field__control {
            width: 58px;
            height: 32px;
            color: #363840;
            -webkit-text-fill-color: #363840;
            // background: #EFEFEF;
            border: 1px solid #EFEFEF;
            border-radius: 4px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
