<template>
  <div class="questionBox">
    <van-swipe
      ref="swiper"
      class="my-swipe"
      :initial-swipe="currentIndex"
      :loop="false"
      :show-indicators="false"
      @change="onChange"
    >
      <van-swipe-item v-for="(item, index) in examList" :key="index">
        <single-choice
          v-if="item.typeId == 1"
          :question="item"
          :num="page > 1 ? (page - 1) * pageSize + index + 1 : index + 1"
          :isExercises="isExercises"
          :isCollect="isCollect"
          :select="select"
          :examTotal="page > 1 ? (page - 1) * pageSize + examList.length : examList.length"
          @change="answer"
          @showAnswerSheet="showAnswerSheet"
        ></single-choice>
        <multiple-choice
          v-else-if="item.typeId == 2 || item.typeId == 3"
          :question="item"
          :num="page > 1 ? (page - 1) * pageSize + index + 1 : index + 1"
          :isExercises="isExercises"
          :isCollect="isCollect"
          :select="select"
          :examTotal="page > 1 ? (page - 1) * pageSize + examList.length : examList.length"
          @showAnswerSheet="showAnswerSheet"
          @change="answer"
        ></multiple-choice>
        <!-- <Uncertain
          v-else-if="item.typeId == 3"
          :question="item"
          :num="index + 1"
          :isExercises="isExercises"
          @change="answer"
        ></Uncertain> -->
        <judge
          v-else-if="item.typeId == 4"
          :question="item"
          :num="page > 1 ? (page - 1) * pageSize + index + 1 : index + 1"
          :isExercises="isExercises"
          :isCollect="isCollect"
          :select="select"
          :examTotal="page > 1 ? (page - 1) * pageSize + examList.length : examList.length"
          @showAnswerSheet="showAnswerSheet"
          @change="answer"
        ></judge>
        <Fill
          v-else-if="item.typeId == 5"
          :question="item"
          :num="page > 1 ? (page - 1) * pageSize + index + 1 : index + 1"
          :isExercises="isExercises"
          :isCollect="isCollect"
          :select="select"
          :remainTime="remainTime"
          :examTotal="page > 1 ? (page - 1) * pageSize + examList.length : examList.length"
          @change="answer"
          @showAnswerSheet="showAnswerSheet"
          @changeRemarkRead='changeRemarkRead'
          @updateScore='updateScore'
        ></Fill>
        <question-answer
          v-else-if="item.typeId == 6"
          :question="item"
          :num="page > 1 ? (page - 1) * pageSize + index + 1 : index + 1"
          :isExercises="isExercises"
          :isCollect="isCollect"
          :select="select"
          :remainTime="remainTime"
          :examTotal="page > 1 ? (page - 1) * pageSize + examList.length : examList.length"
          @change="answer"
          @changeRemarkRead='changeRemarkRead'
          @showAnswerSheet="showAnswerSheet"
          @updateScore='updateScore'
        ></question-answer>
        <analysis
          v-else-if="item.typeId == 7 || item.typeId == 8"
          :question="item"
          :num="page > 1 ? (page - 1) * pageSize + index + 1 : index + 1"
          :isExercises="isExercises"
          :isCollect="isCollect"
          :select="select"
          :remainTime="remainTime"
          :examTotal="page > 1 ? (page - 1) * pageSize + examList.length : examList.length"
          @change="answer"
          @changeRemarkRead='changeRemarkRead'
          @showAnswerSheet="showAnswerSheet"
          @updateScore='updateScore'
        ></analysis>
        <!-- <compatibility
          v-else-if="item.typeId == 8"
          :question="item"
          :num="index + 1"
          :isExercises="isExercises"
          :select="select"
          @change="answer"
        ></compatibility> -->
        <div class="loading" v-else>题目加载中~</div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import SingleChoice from "./singleChoice";
import MultipleChoice from "./multipleChoice";
import Uncertain from "./uncertain";
import Fill from "./fill";
import Judge from "./judge";
import QuestionAnswer from "./questionAnswer";
import Analysis from "./analysis";
import Compatibility from "./compatibility";
export default {
  components: {
    SingleChoice,
    MultipleChoice,
    Uncertain,
    Fill,
    Judge,
    QuestionAnswer,
    Analysis,
    Compatibility
  },
  props: {
    examList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    currentIndex: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    select: {
      type: Boolean,
      default: function () {
        return true
      }
    },
    remainTime: {
      // 剩余时间 (s) remainTime='批阅/自改'(批阅状态或者练习自改状态)
      type: String,
      default: function () {
        return ''
      }
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  data () {
    return {
      result: null,
      page: 1,
      pageSize: 30
    };
  },
  watch: {
    examList: {
      deep: true,
      handler (newV, oldV) {
        console.log(newV,6612312312312);
        this.examList = newV
        // console.log(this.examList,'examList2');
      // if (newV.selfScore || newV.selfScore == 0) {
      //   this.getScore = newV.selfScore
      // }
      }
    },
    '$store.state.page'(newVal, oldVal) {
      this.page = newVal
    }
  },
  created () {
    this.pageSize = this.$store.state.pageSize 
    // console.log(this.examList,'examList');
  },
  methods: {
    // getQuestion(val) {
    //   console.log(val,5555)
    //   this.examList = val;
    // },
    // 调取答题卡
    showAnswerSheet () {
      this.$emit('showAnswerSheet')
    },
    answer (val) {
      console.log("单选题选择了的呀", val)
      this.$emit("question-result", val)
    },
    onChange (e) {
      console.log(e)
      document.body.click();
      this.$nextTick(() => {
        this.$emit("question-index", e);
      });
    },
    changeRemarkRead (val) {
      this.$emit('changeRemarkRead', val)
    },
    updateScore (val) {
      this.$emit('updateScore', val)
    }
  },
};
</script>

<style lang="scss" scoped>
.questionBox {
  overflow:visible ;
}
.my-swipe {
  overflow: visible;
}
.loading {
  margin-top: 20px;
  font-size: 16px;
  color: #333;
  text-align: center;
}
</style>
