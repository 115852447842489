<!--
 * @Author: xwen
 * @Date: 2020-02-27 09:43:37
 * @LastEditTime: 2021-03-17 17:11:59
 * @LastEditors: Please set LastEditors
 * @Description: 填空题
 -->
<template>
  <div class="question question-fill-exam" :style="{height:(parent? 'auto':'calc(100vh - 58px - 70px)')}">
    <rightErrorTitle
      v-if="(!select && !parent) || specialEvent()"
      ref="rightErrorTitle"
      :select='true'
      :num='num'
      :remainTime='remainTime'
      :question='question'
      :examTotal='examTotal'
      :isCollect="isCollect"
      @showAnswerSheet='showAnswerSheet'
      @changeSelect='changeSelect'
      :isExercises='isExercises'
    ></rightErrorTitle>
    <div class="title">
      <span v-if="parent">{{num}}.(填空题) </span>
      <span v-else>{{num}}.(填空题) </span>
      <span v-html="question.stem" ref="stem" class="stem" ></span>
    </div>
    
    <div class="content">
      <van-field
        @blur="onInput"
        :class="{bg_gary:!select}"
        v-for="(item, index) in list"
        :key="index"
        :placeholder="`请输入第${index + 1}空`"
        v-model="list[index]"
        :label-width="`${question.fontSizeMultiple*40}px`"
        :disabled="!select || (isExercises && isSubmit)" />
        <!-- :label="index === 0 ? '作答:' : ''" -->
      <van-button
        v-if="!isSubmit && isExercises"
        class="submit"
        round size="small"
        color="#18A6F3"
        type="info"
        @click="submit">提 交</van-button>
      <van-button
        v-if="isSubmit && isExercises"
        class="submit"
        round size="small"
        color="#aaa"
        type="info">已提交</van-button>
    </div>
    <!-- 解析 -->
    <answerArea
      ref="answerArea"
      v-if="!select || (isExercises && isSubmit)"
      :question='question'
      :remainTime='remainTime'
      @updateScore='updateScore'
      :isExercises='isExercises'
      :isCollect="isCollect"
    ></answerArea>
    <rightErrorTitle
      v-if="(!select && parent && isCollect < 1) || (isExercises && parent && isSubmit)"
      ref="rightErrorTitle2"
      :select='true'
      :parent='parent'
      :num='num'
      :isExercises='isExercises'
      :question='question'
      :remainTime='remainTime'
      @changeSelect='changeSelect'
    ></rightErrorTitle>
    <!-- <div v-if="isExercises && select && !hasAnswered(question.selfAnswer, question.answer)" class="analysis">
      参考答案：<span v-html="answerStr"></span>
      <div class="anly">
        解析：<span v-html="question.analysis || '无'"></span>
      </div>
    </div> -->
  </div>
</template>

<script>
import { Toast } from 'vant'
import rightErrorTitle from '../rightErrorTitle'
import answerArea from '../answerArea'
/*
 * 去除字符串中的所有空格
 */
function removeAllSpace (str) {
  if (!str || typeof str !== 'string') return str
  return str.replace(/\s+/g, '').replace('&nbsp;', '').replace(/ /g, '')
}
export default {
  components: {
    rightErrorTitle,
    answerArea
  },
  name: 'Fill',
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    select: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    parent: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    remainTime: {
      // 剩余时间 (s) remainTime='批阅/自改'(批阅状态或者练习自改状态)
      type: String,
      default: function () {
        return ''
      }
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        if(this.isExercises) return;
        if (newV.selfAnswer == null || oldV.selfAnswer === null) {
          let list = JSON.parse(oldV.answer)
          if (list.length > 0) {
            let list2 = []
            list.forEach((item, index) => {
              list2.push('')
              if (index === list.length - 1) {
                this.selfAnswer = []
                this.$set(this, 'list', list2)
              }
            })
          }
        }
      }
    }
  },
  computed: {
    answerStr () {
      try {
        const answerArr = JSON.parse(this.question.answer).join(' | ')
        return answerArr
      } catch (e) {
        return this.question.answer
      }
    },
    isRight () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true
        } else {
          return selfAnswer === answer.trim()
        }
      }
    },
    showRight () {
      return function () {
        for (let i in this.answer) {
          for (let j in this.selfAnswer) {
            if (this.answer[i] !== this.selfAnswer[j]) {
              return false
            }
          }
          if (i === this.answer.length - 1) {
            return true
          }
        }
      }
    },
    hasAnswered () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true
        }
      }
    }
  },
  data () {
    const list = this.fillNum()
    return {
      selfAnswer: this.question.selfAnswer || '',
      list: list,
      isSubmit: !!this.question.selfAnswer
    }
  },
  mounted () {
    this.$nextTick(function () {
      if (this.question) {
        this.initTitle()
      }
    })
  },
  methods: {
    // 调取答题卡
    showAnswerSheet () {
      this.$emit('showAnswerSheet')
    },
    initTitle () {
      if(this.isExercises || this.isCollect == 1 || this.isCollect == 2) return;
      let str = '('
      if (this.parent) {
        str = '(本题'
      }
      this.$refs.stem.innerHTML = this.$refs.stem.innerHTML + str + this.question.score + '分)'
    },
    onInput () {
      // this.isSubmit = false
      if(this.isExercises) return;
      const isAnswerNull = this.list.every(item => removeAllSpace(item) === '')
      if (isAnswerNull) {
        Toast('请选择或填写答案')
        // return
      }
      let obj = {
        ...this.question,
        questionId: this.question.questionId,
        num: this.num,
        selfAnswer: JSON.stringify(this.list || [])
      }
      // this.isSubmit = true
      this.$emit('change', obj)
    },
    fillNum () {
      if (this.question.selfAnswer) {
        return JSON.parse(this.question.selfAnswer)
      } else {
        let arr = this.question.stem.match(/\(\s\)/g)
        let arr3 = this.question.stem.match(/（\s）/g)
        if (arr || arr3) {
          if (!arr) {
            arr = []
          }
          arr.push.apply(arr, arr3)
        }
        let arr2 = []
        for (let i = 0; i < arr.length; i++) {
          arr2[i] = ''
        }
        return arr2
      }
    },
    // 批阅对错
    changeSelect (val) {
      this.$emit("changeRemarkRead", val)
    },
    // 打分/更新分数
    updateScore (val) {
      let obj = {
        ...val,
        num: this.num
      }
      if (this.parent) {
        this.$refs.rightErrorTitle2.updateScore(val.selfScore)
      } else {
        this.$refs.rightErrorTitle.updateScore(val.selfScore)
      }
      this.$emit("updateScore", obj)
    },
    submit () {
      console.log('list', this.list)
      const isAnswerNull = this.list.every(item => removeAllSpace(item) === '')
      if (isAnswerNull) {
        Toast('请选择或填写答案')
        return
      }
      let obj = {
        ...this.question,
        questionId: this.question.questionId,
        num: this.num,
        selfAnswer: JSON.stringify(this.list || [])
      }
      this.isSubmit = true
      this.$emit('change', obj)
    },
    // 判断是否在专项练习下做了题
    practiceEvent() {
      let bool = this.isExercises && this.question.selfAnswer ? true : false;
      return bool;
    },
    specialEvent() {
      let bool = this.isExercises && this.question.selfAnswer && !this.question.parentId;
      return bool;
    }
  }
}
</script>
<style lang="scss">
  .question-fill-exam {
    .stem {
      display: block;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #363840;
      line-height: 28px;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 70vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        max-width: 100%;
        vertical-align: middle;
      }
    }
    .quesion-analysis {
      display: block;
      p {
        margin: 0;
        padding: 0;
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      span {
        // color: #333 !important;
        // font-size: 16px !important;
        // line-height: 26px !important;
      }
      p[block-img] {
        text-align: center;
        img {
          width: 60vw !important;
          height: auto;
          border: 5px;
        }
      }
      img {
        height: 40px;
        vertical-align: middle;
      }
    }
  }
</style>
<style lang="scss" scoped>
::v-deep .question .content .van-field--disabled .van-cell__value .van-field__body .van-field__control {
  color: #363840;
}
  .question {
    width: 100%;
    // height: calc(100vh - 58px - 30px);
    box-sizing: border-box;
    padding: 17px 15px 15px 15px;
    overflow: auto;
    .title {
      color: #333;
      font-size: 16px;
      line-height: 26px;
      .kinds {
        display: inline-block;
        line-height: 17px;
        color: #fff;
        text-align: center;
        background: #18A6F3;
        border-radius: 8px 8px 0 8px;
        margin-right: 16px;
        padding: 2px 6px;
        font-size: 12px;
      }
      .score {
        color: #999;
      }
    }
    .img {
      width: 80%;
      height: auto;
      display: block;
      margin: 20px auto;
    }
    .content {
      margin-top: 30px;
      margin-bottom: 30px;
      .van-cell {
        border-radius: 10px;
        border: 1px solid #EFEFEF;
        margin-bottom: 10px;
      }
      .bg_gary {
        background: #EFEFEF;
      }
      .submit {
        display: block;
        margin: 20px auto;
      }
      .night{
        ::v-deep .van-field__control{
          color:#ddd;
        }
      }
    }
    .analysis {
      font-size: 0.4rem;
      color: #333;
      border-radius: 0.08rem;
      line-height: 0.666667rem;
      overflow: hidden;
      word-wrap: break-word;
      span {
        color: #999;
      }
      .anly {
        text-indent: 30px;
      }
    }
  }
</style>
