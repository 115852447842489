<!--
 * @Date: 2020-02-06 13:14:33
 * @LastEditors: Please set LastEditors
 * @Author: xw
 * @LastEditTime: 2021-06-17 14:30:14
 * @Description: 单选题
 -->
<template>
  <div class="question questio-single-exam" :style="{height:(parent? 'auto':'calc(100vh - 58px - 70px)')}">
    <rightErrorTitle v-if="(!select && !parent) || specialEvent()"
      :showRight='question.selfAnswer===question.answer'
      :examTotal='examTotal'
      :num='num'
      :isCollect="isCollect"
      @showAnswerSheet='showAnswerSheet'
      ></rightErrorTitle>
    <div class="title">
      <!-- <div>
        <span class="kinds">单选题</span>
      </div> -->
      <span v-if="parent">({{this.num}}).({{question.typeId===1 ? '单选题':'多选题'}}) </span>
      <span v-else>{{this.num}}.({{question.typeId===1 ? '单选题':'多选题'}}) </span>
      <span
        class="stem"
        ref="stem"
        v-html="question.stem"
      ></span>
    </div>
    
    <div class="content">
      <template v-if="question.typeId === 1">
        <van-radio-group v-model="selfAnswer" @change="singleChoice" :disabled="!select || practiceEvent()">
          <van-radio
            v-for="(item, index) in formatConversion(question.optionContents)"
            :key="index"
            :name="item.name"
            :class="getSingleResult(selfAnswer,item.name,question.answer)"
          >
            <div
              slot="icon"
              class="radio"
              :class="getSingleResult(selfAnswer,item.name,question.answer)">{{ item.name }}</div>
            <div class="option-content" v-html="item.content"></div>
          </van-radio>
        </van-radio-group>
      </template>
      <!-- 多选题 -->
      <template v-else-if="question.typeId === 2">
        <van-checkbox-group v-model="selfAnswer" @change="multipleChoice" :disabled="select">
          <van-checkbox v-for="(item, index) in formatConversion(question.optionContents)" :key="index" :name="item.name">
            <span slot="icon" :class="['checkbox', getMultipleResult(selfAnswer, item.name, question.answer)]" :style="`font-size:${question.fontSizeMultiple*16}px;color:${question.colorMode=='day'?'#333':'#ddd'};border:${question.colorMode=='day'?'none':'1px solid #ddd'};line-height:${question.fontSizeMultiple*25}px;height:${question.fontSizeMultiple*25}px;width:${question.fontSizeMultiple*25}px;`">{{ item.name }}</span>
            <span
            v-if="!!item.content"
            class="option-content" :class="getMultipleResult(selfAnswer, item.name, question.answer)" v-html="item.content" :style="`font-size:${question.fontSizeMultiple*16}px;line-height:${question.fontSizeMultiple*26}px;color:${question.colorMode=='day'?'#333':'#ddd'}`"></span>
          </van-checkbox>
        </van-checkbox-group>
      </template>
      <!-- <template v-else>
        <div v-for="(item, index) in JSON.parse(question.optionsContent)" :key="index" class="option">
          <span>{{ item.name }}. </span>
          <span v-html="item.content" />
        </div>
      </template> -->
    </div>
    <!-- 答题解析区 -->
    <answerArea
      ref="answerArea"
      v-if="!select || practiceEvent()"
      :question='question'
      :isExercises='isExercises'
      :isCollect='isCollect'
    ></answerArea>
  </div>
</template>

<script>
import { Toast } from 'vant'
import rightErrorTitle from '../rightErrorTitle'
import answerArea from '../answerArea'
export default {
  components: {
    rightErrorTitle,
    answerArea
  },
  props: {
    question: {
      type: Object,
      default: function () {
        return {}
      }
    },
    num: {
      type: Number,
      default: function () {
        return 1
      }
    },
    isExercises: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    select: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    parent: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    examTotal: {
      type: Number,
      default: function () {
        return 0
      }
    },
    isCollect: {
      // 1:收藏 2:错题
      type: Number,
      default: function () {
        return 0
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      if (this.question) {
        this.initTitle()
      }
    })
  },
  watch: {
    question: {
      deep: true,
      handler (newV, oldV) {
        console.log(newV,985)
        if(this.isExercises || this.isCollect >= 1) return;
        if (oldV.selfAnswer !== newV.selfAnswer || oldV.selfAnswer === null) {
          if (newV.selfAnswer == null) {
            this.selfAnswer = ''
          }
        }
      }
    }
  },
  data () {
    return {
      selfAnswer: this.question.selfAnswer,
      isSubmited: !!this.question.selfAnswer,
      result: ''
    }
  },
  created () {
    console.log(this.question, 'question', this.num)
  },
  computed: {
    // selfAnswer () {
    //   return this.question.selfAnswer
    // },
    answerStr () {
      try {
        const answerArr = JSON.parse(this.question.answer).join('')
        return answerArr
      } catch (e) {
        return this.question.answer
      }
    },
    isRight () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true
        } else {
          // eslint-disable-next-line
          let answerStr = answer.replace(/[,|\[|\]|\"|\\]/g, '')
          return selfAnswer.join('') === answerStr
        }
      }
    },
    hasAnswered () {
      return function (selfAnswer, answer) {
        if (!selfAnswer) {
          return true
        }
      }
    },
    getMultipleResult () {
      return function (selfAnswer, name, answer) {
        const result = selfAnswer.includes(name) ? 'right' : ''
        return result
      }
    },
    choice () {
      if (this.question.typeId === 1) {
        return this.question.selfAnswer
      } else {
        const selfAnswer = this.question.selfAnswer || ''
        return selfAnswer ? JSON.parse(selfAnswer) : []
      }
    },
    // 单选
    getSingleResult () {
      return function (selfAnswer, name, answer) {
        let result = ''
        if (!this.select || (this.isExercises && selfAnswer)) {
          if (name === selfAnswer) {
            if (answer === selfAnswer) {
              result = 'right'
            } else {
              result = 'error'
            }
          } else if (name === answer) {
            result = 'right'
          }
        } else {
          result = name === selfAnswer ? 'select' : ''
        }
        return result
      }
    },
    formatConversion () {
      return function (str) {
        if (str) {
          return JSON.parse(str)
        } else {
          return []
        }
      }
    }
  },
  methods: {
    // 调取答题卡
    showAnswerSheet () {
      this.$emit('showAnswerSheet')
    },
    initTitle () {
      if(this.isExercises || this.isCollect == 1 || this.isCollect == 2) return;
      let str = '('
      if (this.parent) {
        str = '(本题'
      }
      this.$refs.stem.innerHTML = this.$refs.stem.innerHTML + str + this.question.score + '分)'
    },
    // 多选处理
    multipleChoice (e) {
      console.log(e, '<<<')
      this.result = _.cloneDeep(this.selfAnswer)
      // this.isSubmit = false
      if (this.result.length === 0) {
        Toast({
          message: '请选择或填写答案'
        })
        return
      }
      // if (this.result && this.result.length < 2) {
      //   Toast({
      //     message: ''
      //   })
      //   return
      // }
      const obj = {
        ...this.question,
        questionId: this.question.questionId,
        selfAnswer: JSON.stringify(this.result.sort())
      }
      this.$nextTick(() => {
        this.isSubmit = true
      })
      this.$emit('change', obj)
    },
    // 单选处理
    singleChoice (e) {
      // this.isSubmited = false
      // console.log(e, this.selfAnswer, 999)
      // let obj = {
      //   ...this.question,
      //   questionId: this.question.id,
      //   selfAnswer: this.selfAnswer
      // }
      // this.$emit('change', obj)

      console.log(this.selfAnswer, 'selfAnswer')
      const obj = {
        ...this.question,
        questionId: this.question.questionId,
        num: this.num,
        selfAnswer: this.selfAnswer
      }
      if (obj.selfAnswer.length === 0) {
        Toast({
          message: '请选择或填写答案'
        })
        return
      }
      this.$emit('change', obj)
      // this.isSubmited = true
    },
    // submit () {
    //   console.log(this.selfAnswer)
    //   let obj = {
    //     ...this.question,
    //     questionId: this.question.id,
    //     selfAnswer: this.selfAnswer
    //   }
    //   if (obj.selfAnswer.length === 0) {
    //     Toast({
    //       message: '请选择或填写答案'
    //     })
    //     return
    //   }
    //   this.$emit('change', obj)
    //   this.isSubmited = true
    // }

    // 判断是否在专项练习下做了题
    practiceEvent() {
      let bool = this.isExercises && this.question.selfAnswer ? true : false;
      return bool;
    },
    specialEvent() {
      let bool = this.isExercises && this.question.selfAnswer && !this.question.parentId;
      return bool;
    }
  }
}
</script>

<style lang="scss">
.questio-single-exam {
  .stem {
    // display: block;
    display: contents !important;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    p {
      margin: 0;
      padding: 0;
      color: #333;
      font-size: 16px;
      line-height: 26px;
      display: contents !important;
      word-wrap: break-word;
      word-break: break-all;
      overflow: hidden;
    }
    span {
      color: #333;
      font-size: 16px;
      line-height: 26px;
    }
    p[block-img] {
      text-align: center;
      img {
        width: 70vw !important;
        height: auto;
        border: 5px;
      }
    }
    img {
      max-width: 100%;
      height: 40px;
      vertical-align: middle;
    }
  }
  .option-content {
    color: #323233;
    p {
      margin: 0;
      padding: 0;
      display: inline;
      img {
        display: block;
      }
      // color: #333 !important;
      // font-size: 15px !important;
      // line-height: 26px !important;
    }
    span {
      // color: #333 !important;
      // font-size: 15px !important;
      // line-height: 26px !important;
    }
    p[block-img] {
      text-align: left;
      img {
        width: 30vw !important;
        height: auto;
      }
    }
    img {
      max-width: 100%;
      height: 40px;
      vertical-align: middle;
    }
  }

}
</style>

<style lang="scss" scoped>
.question {
  width: 100%;
  // height: calc(100vh - 58px - 70px);
  box-sizing: border-box;
  padding: 17px 15px 15px 15px;
  overflow: auto;
  .rightError {
    position: absolute;
    top: -16px;
    height: 16px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333;
    line-height: 16px;
  }
  .title {
    color: #333;
    font-size: 16px;
    line-height: 26px;
    .kinds {
      display: inline-block;
      line-height: 17px;
      color: #fff;
      text-align: center;
      background: #18a6f3;
      border-radius: 8px 8px 0 8px;
      margin-right: 16px;
      padding: 2px 6px;
      font-size: 12px;
    }
    .score {
      color: #999;
    }
  }
  .img {
    width: 80%;
    height: auto;
    display: block;
    margin: 20px auto;
  }
  .content {
    margin-top: 30px;
    margin-bottom: 30px;
    .select {
      border-color: #1A7EE2 !important;
    }
    .right {
      border-color: #1CB74D !important;
    }
    .error {
      border-color: #E21A1A !important;
    }
    .van-radio {
      margin-bottom: 15px;
      color: #333;
      min-height: 38px;
      align-items: baseline;
      -webkit-box-align:inherit !important;
      -webkit-align-items: inherit !important;
      align-items: inherit !important;
      border: 0.025rem solid #EFEFEF;
      border-radius: 10px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1A7EE2;
      line-height: 20px;
      ::v-deep .van-radio__icon{
        height: auto !important;
      }
      ::v-deep .van-radio__label{
        display: flex;
        align-items: center;
        margin: 5px;
      }

      .radio {
        border-right: 1px solid #EFEFEF;
        display: inline-block;
        width: 40px;
        min-height: 40px;
        height: 100% !important;
        line-height: 25px;
        font-size: 16px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .select {
        background: #1A7EE2;
        color: #fff;
      }
      .right {
        background: #1CB74D;
        color: #fff;
      }
      .error {
        background: #E21A1A;
        color: #ffffff;
      }
    }
  }
  .infoContent {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    .info.selfAnswer {
      span.infoTitle {

      }

      span {

      }
    }
    .info.answer {
      span.infoTitle.underline {

      }

      span {

      }
    }
    .info.analysis {
      span.infoTitle {

      }

      span.quesion-analysis {
        // display: block;
        display: -webkit-inline-box;
        p {
          margin: 0;
          padding: 0;
          // color: #333 !important;
          // font-size: 16px !important;
          // line-height: 26px !important;
        }
        span {
          // color: #333 !important;
          // font-size: 16px !important;
          // line-height: 26px !important;
        }
        p[block-img] {
          text-align: center;
          img {
            width: 60vw !important;
            height: auto;
            border: 5px;
          }
        }
        img {
          height: 40px;
          vertical-align: middle;
        }
      }
    }
    // .analysis {
    //   margin: 30px 15px 70px;
    //   padding: 14px 18px;
    //   font-size: 15px;
    //   color: #333;
    //   background: #eee;
    //   border-radius: 3px;
    //   line-height: 25px;
    //   overflow: hidden;
    //   word-wrap: break-word;
    //   span {
    //     color: #999;
    //   }
    //   .anly {
    //     text-indent: 30px;
    //   }
    // }

  }
}
</style>
